@import "../../../styles/variables";

.rewards {
  margin-top: 32px;
  padding: 30px 24px;
  background-color: $white-color;
  border-radius: 10px;

  @media screen and (max-width: $breakpoint-small) {
    margin-top: 16px;
    padding: 16px 12px;
  }

  .mywalletTable {
    justify-content: space-between;
    margin-bottom: 24px;

    .tableButtons {
      max-width: 285px;
      width: 100%;
      justify-content: space-between;

      .eachButton {
        height: 28px;
        font-size: 14px;
        font-weight: 500;
        width: auto;
        padding: 2px 12px;
        border-color: $input-border;
        color: $peragraph-text;
      }

      .active {
        background-color: $link-color;
        color: $white-color;
        border: none;
      }
    }
  }

  .claimBtn {
    padding: 0 39px;
    height: 32px;
  }

  .darkText {
    color: $title-color;
    font-weight: 700;
    font-size: 14px;

    span {
      font-size: 12px;
      font-weight: 500;
      color: $peragraph-text;
    }
  }
}

.popupcont {
  background-color: $table-row-bg;
  padding: 14px 20px;
  border-radius: 12px;

  @media screen and (max-width: $breakpoint-small) {
    padding: 16px;
  }
}

.popupItems {
  justify-content: space-between;

  li {
    font-weight: 400;
    line-height: 140%;
    font-size: 14px;
    color: $peragraph-text;
    margin-bottom: 10px;

    strong {
      color: $title-color;
    }
  }
}

.confirmButton {
  justify-content: space-between;
  margin-top: 32px;

  @media screen and (max-width: $breakpoint-small) {
    margin-top: 24px;
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .buttons {
    width: calc(50% - 8px);
    margin: 0 8px;

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;

      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }
}

.rewardTitel {
  h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 28px;

    @media screen and (max-width: $breakpoint-small) {
      font-size: 20px;
    }
  }
}

.eligibletext {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: $peragraph-text;
  margin-bottom: 32px;

  @media screen and (max-width: $breakpoint-small) {
    margin-bottom: 24px;
  }
}

.transactionsTitel {
  h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 28px;
    text-align: center;

    @media screen and (max-width: $breakpoint-small) {
      font-size: 20px;
    }
  }
}

.gotItButton {
  max-width: 220px;
  margin: auto;

  @media screen and (max-width: $breakpoint-small) {
    max-width: 100%;
  }
}

.textCenter {
  text-align: center;
}

@import "../../../styles/variables";

.transactions {
  margin-top: 32px;
  padding: 30px 24px;
  background-color: $white-color;
  border-radius: 10px;

  h3 {
    color: $title-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media screen and (max-width: $breakpoint-small) {
    margin-top: 16px;
    padding: 16px 12px;
  }

  .mywalletTable {
    justify-content: space-between;
    margin-bottom: 24px;

    @media screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
    }

    .tableButtons {
      max-width: 413px;
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: $breakpoint-small) {
        max-width: 100%;
      }

      .eachButton {
        height: 28px;
        font-size: 14px;
        font-weight: 500;
        width: auto;
        padding: 2px 12px;
        border-color: $input-border;
        color: $peragraph-text;
        gap: 0 !important;

        @media screen and (max-width: $breakpoint-small) {
          span {
            display: none;
          }
        }
      }

      .active {
        background-color: $main-color-50;
        color: $white-color;
        border: none;
      }
    }

    .douwnload {
      height: 32px;
      max-width: 184px;
      width: 100%;

      @media screen and (max-width: $breakpoint-small) {
        max-width: 100%;
      }

      .douwnloadIcon {
        margin-right: 8px;

        path {
          fill: $btn-text-color;
        }
      }
    }
  }

  .pink {
    color: #ff008a;
    font-weight: 700;
  }

  .blue {
    font-weight: 700;
    color: $color-blue;
  }

  .date {
    color: $peragraph-text;
  }

  .loadMore {
    height: 32px;
    max-width: 184px;
    width: 100%;
    margin-top: 12px;
    font-size: 14px;

    @media screen and (max-width: $breakpoint-small) {
      max-width: 100%;
    }
  }

  .amount {
    font-weight: 500;
    color: $title-color;
    font-size: 14px;
    text-transform: uppercase;
  }

  .transactionType {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .typeText {
    font-weight: 500;
    color: $title-color;
    font-size: 14px;
  }

  .logoContainer {
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  a {
    color: unset;
  }

  .buyIcon {
    transform: rotate(180deg);

    path {
      fill: #3e3aff;
      stroke: #3e3aff;
    }
  }

  .iconContainer {
    width: 40px;
    height: 40px;
    background-color: $table-row-bg;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import "../../styles/variables.scss";
@import "../../colorVariables.scss";

.productContainer {
  .dashboardCard {
    @media screen and (max-width: $breakpoint-small) {
      padding: 16px;
    }
  }

  .header {
    // margin-bottom: 16px;
  }

  .graphContainer {
    width: 100%;
    margin-bottom: 20px;

    .cardInner {
      .svg {
        width: 100%;
        fill: $main-color;
      }

      .cardInnerContent {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .cardInnerContentLeft {
          width: 100%;
          text-align: left;
          margin-bottom: 24px;

          .priceMob {
            display: none;

            .priceContainerMob {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              h6 {
                margin: 0px;
              }
            }
          }

          .priceContainer {
            display: flex;
            align-items: center;
            gap: 5px;

            h3 {
              font-style: normal;
              font-weight: 800;
              font-size: 40px;
              line-height: 53px;
              letter-spacing: 0.02em;
              color: $title-color;
              margin-bottom: 6px;
            }

            span {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              letter-spacing: 0.02em;
              color: $title-color;
              margin-top: 10px;
            }
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $title-color;
            margin-bottom: 8px;
          }

          h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: $title-color;
            margin-bottom: 20px;
          }
        }

        .cardInnerContentRight {
          display: flex;
          align-items: center;
          gap: 20px;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: $title-color;
          }

          .active {
            width: 40px;
            height: 22px;
            background: #1e1e1e;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white-color;
          }
        }
      }
    }
  }

  .reward {
    width: 100%;
    margin-top: 20px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: $title-color;
    text-align: start;
  }

  .flex {
    align-items: baseline;
    gap: 20px;
  }

  .rightContainer {
    width: 60%;
  }

  .myTokenLeftContainer {
    width: 40%;
  }

  .mt {
    margin-top: 20px;
  }

  .tokenContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;

    .chart {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 10;

      @media (max-width: $breakpoint-small) {
        flex-direction: column;
      }

      .pie {
        position: relative;
      }

      .total {
        position: absolute;
        top: 83px;
        left: 72px;

        h2 {
          font-weight: 700;
          font-size: 28px;
          line-height: 37px;
          color: #1f2937;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #1f2937;
          text-transform: uppercase;
        }
      }
    }

    .chartSingle {
      justify-content: center;
    }

    .displayNone {
      display: none;
    }

    .chartTextContainer {
      width: 100%;

      .pieInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .pieLeft {
          display: flex;
          align-items: center;
          gap: 7.86px;

          h4 {
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: $input-label;
          }
        }

        .bullet {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: $input-label;
        }
      }
    }

    .myTokenContainer {
      width: 100%;
      background-color: $bg-color;
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      padding: 10px;

      .token {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        hr {
          display: none;
          background: $input-border;
          width: 100%;
          height: 0.5px;
          border: 0px;
          margin: 16px 0px;
        }

        @media (max-width: $breakpoint-small) {
          flex-direction: column;
          align-items: flex-start;

          hr {
            display: block;
          }

          // .divider{
          //     display: block;
          // }
        }

        .tokenLeft {
          display: flex;
          align-items: center;
          gap: 10px;

          .contentLeft {
            h5 {
              font-weight: 700;
              font-size: 16px;
              line-height: 21px;
              color: $title-color;
              margin: 0px !important;
            }

            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
              color: $input-label;
              margin-top: 8px;
            }

            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
              color: $color-blue;
            }

            .icon {
              margin-right: 7.61px;
            }
          }
        }

        .contentRight {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 40px;

          @media (max-width: $breakpoint-small) {
            width: 100%;
          }

          .contentRightData {
            p {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: $table-th;
              text-align: left;
            }

            h6 {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.02em;
              color: $title-color;
              margin-top: 8px;
            }
          }
        }
      }

      .left {
      }

      .right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .contentLeft {
          h5 {
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: $title-color;
            margin: 0px !important;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $input-label;
            margin-top: 8px;
          }

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $color-blue;
          }

          .icon {
            margin-right: 7.61px;
          }
        }

        .contentRight {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;

          .contentRightData {
            p {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: $input-label;
            }

            h6 {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: 0.02em;
              color: $title-color;
              margin-top: 8px;
            }
          }
        }
      }
    }

    .transWrapper {
      height: 370px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 4px;
      // max-height: 1010px;
      overflow-y: scroll;

      .noData {
        margin-top: 24px;
        font-size: 16px;
        color: $input-label;
        font-weight: 500;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        background-color: $white-color;

        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $color-yellow;
        padding: 5px 10px;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      text-align: start;
    }

    .tokenWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .tranContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h5 {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: $title-color;
          text-transform: uppercase;
        }

        .tranHead {
          h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $title-color;
            text-align: left;
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $input-label;
            text-align: left;
            margin-top: 4px;
          }
        }
      }

      .tokenContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;

        .tokenItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }

        h4 {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: $title-color;
          text-transform: uppercase;
        }

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: $color-blue;
        }

        .arrowUp {
          margin-right: 5px;
          margin-bottom: 2px;
        }

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: $input-label;
          margin-left: 4px;
        }

        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: $dark-gray;
        }

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: $title-color;
        }
      }
    }
  }

  .transactionContainer {
    width: 40%;
  }

  .iconContainer {
    width: 40px;
    height: 40px;
    background-color: $table-row-bg;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.noDataFound {
  p {
    font-size: 16px;
    color: $input-label;
    font-weight: 500;
  }
}

.claim {
  width: 100px;

  .claimBtn {
    height: 32px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: $btn-text-color;
  }
}

.reward1 {
  width: 60% !important;
  margin-top: 20px;
}

.balance {
  width: 40% !important;
  margin-top: 20px;

  .token {
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      margin: 24px 0px 16px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: $peragraph-text;
    }

    span {
      color: $color-yellow;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.balanceWrapper {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $peragraph-text;
    text-align: left;
  }
}

.balanceContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balanceContentLeft {
  display: flex;
  align-items: center;
  gap: 5px;

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: $title-color;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: $title-color;
  }
}

.balanceContenRight {
  width: 100px;
}

.flexContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.stakCard {
  .rowMobileContainer {
    display: none;
  }

  .stakCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      text-align: start;
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: $color-yellow;
      border-radius: 24px;
      padding: 2px 10px;
      cursor: pointer;
    }
  }

  .stakWrapper {
    // background-color: #F9F7FC;
    // border-radius: 12px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // background-color: $table-rowbg);
    // gap: 40px;
    // padding: 10px
    // margin-bottom: 20px;
  }
}

.stakHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $main-color-50;
  }
}

.RewardsHeader {
  h4 {
    flex: 1 0 0px;
    max-width: 100%;
    min-width: 100px;
    text-align: left;
  }
}

@media (max-width: 1150px) {
  .cardLeft {
    width: 100% !important;
  }

  .cardRight {
    width: 100% !important;
  }

  .cardInnerContent {
    align-items: flex-start !important;
  }
}

@media (max-width: $breakpoint-medium) {
  .productContainer {
    .graphContainer {
      width: 100%;
    }

    .tokenContainer {
      width: 100%;
    }
  }

  .stakHeader {
    display: none;
  }

  .stakWrapper {
    display: none;
  }

  .rowMobileContainer {
    display: block !important;
  }

  .cardInnerContent {
    flex-direction: column;
  }
}

@media (max-width: $breakpoint-extra-large) {
  .flex {
    flex-direction: column;

    .myTokenLeftContainer {
      width: 100%;
    }

    .rightContainer {
      width: 100%;
    }
  }
}

@media (max-width: 1150px) {
  .flexContainer {
    flex-direction: column;
  }

  .reward1 {
    width: 100% !important;
  }

  .balance {
    width: 100% !important;
  }

  .graphContainer {
    width: 100% !important;
  }

  .tokenContainer {
    width: 100% !important;
  }

  .cardInnerContent {
    .cardInnerContentRight {
      width: 100% !important;
      justify-content: inherit !important;
    }
  }

  .priceMob {
    display: block !important;
  }

  .rate {
    display: none !important;
  }

  .priceContainer {
    span {
      display: none !important;
    }
  }
}

.customTooltip {
  background-color: $purple-light;
  padding: 10px;
  border-radius: 8px;
}

.buyIcon {
  transform: rotate(180deg);

  path {
    fill: #3e3aff;
    stroke: #3e3aff;
  }
}

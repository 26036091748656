@import "../../../styles/_variables";
.airdrop {
  margin-top: 32px;
  @media screen and (max-width: $breakpoint-small) {
    margin-top: 24px;
  }
  .referFridnd {
    background-color: $white-color;
    padding: 32px 24px 40px;
    border-radius: 10px;
    margin-bottom: 24px;
  }
  .Referral {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;
    @media screen and (max-width: 1299px) {
      align-items: flex-start;
    }

    .ReferralP {
      width: 58%;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: $input-placeholder;
      @media screen and (max-width: 1299px) {
        padding-bottom: 0;
      }
      @media screen and (max-width: 757px) {
        width: 100%;
      }
    }
    .ReferralInput {
      width: 40%;
      position: relative;
      @media screen and (max-width: 757px) {
        width: 100%;
        margin-bottom: 16px;
      }
      svg {
        position: absolute;
        right: 22px;
        bottom: 28px;
        cursor: pointer !important;
      }
      input {
        background: $light-gray;
        outline: none;
        color: $color-blue;
        padding-right: 50px;
      }
    }
  }
  .gridsystem {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 5px;
    align-items: center;
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }
  .rightIcon {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

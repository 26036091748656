@import "../../../styles/variables";
@import "../../../styles/mixins";
.customeConfirmationplan {
  // & + button {
  //   display: none;
  // }
  .ConfirmationplanTitle {
    @include flexProperty;
    align-items: flex-start;
  }
  .editPlanBox {
    display: flex;
    // grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    // column-gap: 16px;
    margin-top: 30px;
    @media screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
    }

    .editPlanBoxInner {
      background: $white-color;
      border: 1px solid $input-border;
      border-radius: 10px;
      padding: 13px;
      text-align: center;
      min-height: 252px;
      width: calc(33.33% - 8px);
      margin: 0 8px;
      @media screen and (min-width: 1400px) {
        padding: 24px;
      }
      @media screen and (max-width: $breakpoint-medium) {
        margin: 0 4px;
        padding: 16px;
      }
      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 12px;
        width: 100%;
        padding: 16px;
      }
      .SavingPlan {
        .blueText {
          font-weight: 500;
          font-size: 15px;
          line-height: 140%;
          color: $title-color;
          margin-bottom: 24px;
        }
        .boxIcon {
          margin-bottom: 20px;
          grid-template-columns: auto auto;
          column-gap: 12px;
          display: grid;
          justify-content: center;
          img {
            width: 100%;
          }
          svg {
            width: 100%;
            fill: $main-color-70;
            stroke: $main-color-70;
          }
        }
        .amount {
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: $title-color;
          padding-bottom: 20px;
          border-bottom: 1px solid $input-border;
        }
        .textArea {
          .cryptoTitle {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: $title-color;
          }
          .until {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            color: #9599b5;
            margin-top: 9px;
          }
          .purcheses {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $main-color-70;
            margin-top: 12px;
            display: block;
          }
          .month {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $title-color;
            margin-top: 12px;
          }

          .visa {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: $title-color;
          }
        }
      }
    }
  }
  .buttonGroup {
    @include flexProperty;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 48px 60px;
    @media screen and (max-width: $breakpoint-large) {
      padding: 24px;
    }
    @media screen and (max-width: $breakpoint-small) {
      padding: 0;
      flex-wrap: wrap;
      position: static;
    }
    .viewMyPlan {
      width: 48%;
      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
        margin: 16px 0;
      }
    }
    .SubscribePlan {
      width: 48%;
      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
  }
}
.investPlanDetails {
  min-height: 1100px;
}
.editPlan {
  background-color: transparent;
  outline: none;
  border: 0;
  color: $color-yellow;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  @media screen and (max-width: $breakpoint-small) {
    width: 100%;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 8px;
  }
}
.editPopup {
  text-align: center !important;
  button {
    max-width: 220px;
    margin: auto;
    @media screen and(max-width:$breakpoint-small) {
      max-width: 100%;
    }
  }
}

@import "../../styles/variables";

.root {
  width: 100%;
  height: 100vh;
  position: relative;
  background: $main-gradient;

  @media screen and (max-width: $breakpoint-medium) {
    height: auto;
  }
}

// .logo {
//   padding-top: 54px;
//   padding-left: 75px;
//   display: inline-block;

//   @media screen and (max-width: $breakpoint-large) {
//     padding-top: 22px;
//     padding-left: 45px;
//   }

//   @media screen and (max-width: $breakpoint-small) {
//     padding-left: 16px;
//   }
// }
.logoContainer {
  position: absolute;
  top: 1px;
  right: 17px;
  z-index: 9;

  @media (min-width: $breakpoint-extra-large) {
    top: 1px;
    right: 17px;
  }

  svg {
    width: 160px;
    height: 100px;

    @media screen and (max-width: $breakpoint-medium) {
      display: none !important;
    }
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: center;
  }

  .content {
    max-width: 628px;
    width: 40%;
    background: $white-color;
    border: 1px solid #e8e1f6;
    border-radius: 40px 0px 0px 40px;
    padding: 56px 96px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: $breakpoint-medium) {
      border-radius: 25px 24px 0px 0px;
      width: 100%;
      position: static;
      border: 0;
      max-width: 100%;
      padding: 20px;
      height: auto;
      justify-content: center;
      overflow-y: hidden;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: 24px 16px !important;
    }
  }
}

.container {
  .sideImage {
    width: 60%;
    height: 100%;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $breakpoint-medium) {
      position: static;
      transform: translateY(0);
      height: 400px;
    }

    .img {
      margin-top: -117px;
      text-align: center;
      svg {
        width: 560;
        height: 540px;
        // width: calc(90% - 60px);
        // height: calc(100vh - 60px);
        max-height: 640px;
        margin-bottom: 30px;

        @media screen and (max-width: $breakpoint-medium) {
          width: 100%;
          height: auto;
          margin: 0px;
          height: 300px;
        }

        @media screen and (max-width: $breakpoint-small) {
          margin-top: -40px;
          padding-top: 60px;
        }
      }

      .diversify_text {
        max-width: 640px;
        margin: auto;
        text-align: center;
        min-height: 90px;

        h4 {
          color: $main-color;
          text-align: center;
          font-size: 30px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
        }

        p {
          margin-top: 10px;
          color: $main-color;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }

        @media screen and (max-width: $breakpoint-medium) {
          display: none;
        }
      }
    }

    .diversify_text {
      max-width: 380px;
      margin: auto;
      text-align: center;
      min-height: 90px;

      h4 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: $main-color;
      }

      p {
        margin-top: 10px;
        color: $peragraph-text;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        margin-left: 90px;
        margin-right: 90px;
      }

      @media screen and (max-width: $breakpoint-medium) {
        display: none;
      }
    }
  }
}

@media screen and(max-width:$breakpoint-large) {
  .container .content {
    padding: 25px;
  }
}

@media screen and(max-width:1199px) {
  .container .content {
    padding: 45px;
  }
}

@import "../../../styles/variables.scss";

.container {
    display: flex;
    width: 100%;
    // gap: 40px;
    // flex-wrap: wrap;
    // column-gap: 40px;
    row-gap: 20px;
    // margin-bottom: 20px;
    align-items: center;
    // justify-content: space-evenly;
}


.alignCenter {
    align-items: center;
}

.alignStart {
    align-items: flex-start;

    .margin {
        margin-bottom: 20px;
    }
}

// @media (max-width: $breakpoint-small) {
//     .container {
//         // align-items: baseline;
//     }
// }
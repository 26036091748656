@import "../../styles/variables";
@import "../../styles/mixins";

.checkBoxes {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  @media screen and (max-width: $breakpoint-small) {
    margin-bottom: 24px;
    flex-wrap: wrap;
  }

  .deleteradioBtn {
    margin-left: 70px;

    @media screen and (max-width: $breakpoint-small) {
      margin-left: 0px;
      width: 100%;
      margin-top: 16px;
    }
  }
}

.titleModal {
  text-align: center;

  h2 {
    margin-top: 0px;
  }
}

.deleteModal {
  gap: 20px;
  flex-direction: column;
  text-align: center;

  .closeBtn {
    width: 50%;
  }
}
@import "../../styles/variables.scss";

.inputContainer {
  // min-width: 48%;
  width: 50%;

  .radioLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $input-label;
  }

  .margin8 {
    margin-top: 8px;
  }

  .inputRadio {
    display: flex;
    align-items: center;
    gap: 12px;

    .labelContainer {
      width: 95%;
    }

    &:focus {
      border: 1px solid $main-color;
    }
  }

  .error {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $text-danger;
    margin-right: 15px;
    display: flex;
    align-items: center;
    gap: 3px;

    .info {
      margin-top: 3px;
    }

    svg {
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }

  .labelError {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}

.width0 {
  width: 20px;
}

.width100 {
  width: 100%;
  margin-bottom: 16px;
}

.datePlaceholder {
  // color: $input-placeholder
}

.inputPlaceholder {
  color: $main-color;
}

.inputWrapper {
  width: 100%;
  border: 1px solid $input-border !important;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

input {
  background: $light-gray !important;
  // border-radius: 12px;
  padding: 11px;
  outline: none;
  width: 100%;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 146%;

  &:focus {
    // border: 1px solid $title-color;
    border-radius: 12px;
  }

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $input-placeholder;
  }
}

.active {
  border: 1px solid $title-color;
}

.labelText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $main-color-70;
}

.displayNone {
  display: none !important;
}

.star::after {
  content: "*";
  color: $text-danger;
  margin-left: 3px;
}

.borderError {
  border: 1px solid $text-danger !important;
  border-radius: 12px;
}

.borderNormal {
  // border: 1px solid $input-border !important;
}

.text_center {
  text-align: center;
}

.textarea {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  background: $light-gray;
}

.text_start {
  text-align: start;
}

@media (min-width: $breakpoint-medium) {
  .inputContainer {
    flex-direction: column;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  accent-color: $main-color;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  accent-color: $main-color;
}

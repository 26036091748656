@import "../../styles/variables.scss";

.flaticon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-medium) {
    // li {
    //   display: none;
    // }

    li:first-child {
      display: block;
    }
  }

  a {
    display: inline-block;
  }

  .space {
    padding-left: 16px;
    @media screen and (max-width: $breakpoint-medium) {
      padding-left: 5px;
    }
  }

  .avatar {
    cursor: pointer;
  }
  .profileAvtar {
    height: 26px;
    width: 26px;
  }
  .notification {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 8px;
      top: 5px;
      width: 10px;
      height: 10px;
      background: $color-orange;
      border-radius: 50px;
    }
  }
}

.notifiDetailsPopUp {
  @media screen and (min-width: $breakpoint-small) {
    padding: 0;
  }
}

.notifDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 32px;

  .detailsTitle {
    color: $title-color;
  }
}

.detailsDescription {
  .nextSteoTitle {
    font-size: 20px;
    color: $title-color;
  }

  .peragraph {
    text-align: left;
    margin-top: 16px;
  }

  .date {
    color: $tableheader;
    display: block;
    margin-top: 6px;
  }
}

.picContainer {
  width: 45px;
  height: 45px;
  border: 5px solid $input-border;
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}

@import "../../styles/variables";

.Buttons {
  // flex: 1;
  margin-top: 12px;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 575px) {
    max-width: 95px;
    margin-right: 4px;
    margin-top: 10px;
    width: 100%;
    flex: inherit;
  }

  .month {
    width: 100%;
    background: #f6f6f7;
    border: 1px solid $main-color-70;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    font-size: 15px;
    text-align: center;
    padding: 11.5px 15px;
    color: $input-label;
    cursor: pointer;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    @media screen and (max-width: 575px) {
      padding: 9px;
    }
  }

  .active {
    background-color: $color-white;
    color: $title-color;
  }
}

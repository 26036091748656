@import "../../styles/variables.scss";

.blogContainer {
  width: 100%;

  .blog {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;

    .viewMore {
      .viewMoreBtn {
        margin-top: 0;
        margin-bottom: 0 !important;
      }
    }

    h5 {
      background-color: #fff;
      padding: 10px;
      border-radius: 30px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $color-yellow;
      white-space: pre;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      white-space: nowrap;
    }

    .divide {
      height: 1px;
      background-color: $input-border;
      width: 80%;
      margin: 0 32px;

      @media (max-width: $breakpoint-small) {
        width: 20%;
        display: none;
      }
    }
  }

  .blogContainer {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

@media (max-width: $breakpoint-medium) {
  .blogContainer {
    flex-direction: column;
  }
}

.carouselSlider {
  width: 100%;

  ul {
    li {
      padding: 0 12px;

      @media (max-width: $breakpoint-small) {
        padding: 0 6px;
      }
    }
  }

  button {
    background-color: $btn-text-color;
    &:hover {
      background-color: $stepper-dark;
    }
  }
}

button[type="button"] {
  z-index: 1 !important;
}

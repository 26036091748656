@import "../../styles/variables";

.coinDetail {
  position: relative;

  .exchangeIcon {
    position: absolute;
    top: 40%;
    right: 45%;
  }
}
.stakContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .stakMainDiv {
    width: 50%;
    background-color: $color-white;
    border-radius: 8px;
  }

  .cardWrapper {
    padding: 48px 60px;
    margin: auto;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: $title-color;
      margin-bottom: 28px;
    }

    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 3px;
      border-radius: 50px;
      border: 1px solid $input-border;
      overflow: hidden;
      background-color: #f6f6f7;
    }

    .tokenDetail {
      background-color: $light-gray;
      padding: 24px 20px;
      border-radius: 12px;
      margin-top: 32px;
      .headeritem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 19px;
        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 16px;
        }
      }
      .phoneIcon {
        max-width: 90px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      @media screen and (max-width: $breakpoint-small) {
        flex-wrap: wrap;
      }
      .amount {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $table-th;
        @media screen and (max-width: $breakpoint-small) {
          width: 100%;
          margin-bottom: 4px;
        }
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $title-color;
        @media screen and (max-width: $breakpoint-small) {
          width: 100%;
        }
      }

      .colorChange {
        color: $title-color !important;
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .back {
    margin-bottom: 22.5px;
  }

  .stakContainer {
    .stakMainDiv {
      width: 100%;
    }

    .cardWrapper {
      padding: 16px;
    }
  }
}

@media (min-width: $breakpoint-medium) and (max-width: 1166px) {
  .stakMainDiv {
    width: 100% !important;
  }
}

// colors variables
$black-color: #000000;
$white-color: #ffffff;
$light-gray: #fafafa;
$input-border: #dddeed;
$input-placeholder: #b5b8cb;
$input-label: #5e648f;
$title-color: #1b4458;
$blue-color: #3e3aff;
$label-color: hsla(200, 53%, 23%, 0.7);
$yellow-color: #ffb000;
$bg-color: #f9f7fc;
$button-text-color: #4e307a;
$dark-bg-color: #eee9f6;
$dark-gray: #9599b5;
$main-color: #1b4458;
$main-color-70: hsla(200, 53%, 23%, 0.7);
$main-color-50: hsla(200, 53%, 23%, 0.5);
$main-color-20: hsla(200, 53%, 23%, 0.2);
$main-color-10: hsla(200, 53%, 23%, 0.1);

@import "../../styles/variables";
.blog {
  display: inline-block;
  max-width: 354px;
  width: 100%;
  background: $white-color;
  border-radius: 10px;
  @media screen and (max-width: 1023px) {
    margin-top: 16px;
    max-width: 100%;
  }

  .blogText {
    padding: 20px 16px;
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
    }
    .blogItems {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $input-label;
        display: inline-block;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        display: inline-block;
        color: $title-color;
        height: auto;
        padding: 0;
      }
    }
  }

  .img {
    img {
      width: 100%;
    }
  }
}

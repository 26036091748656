@import "../../colorVariables.scss";

.stakeInfo {
  .stakeYour {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $dark-bg-color;
    border-radius: 10px;
    padding: 48px 30px;
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      padding: 24px;
    }

    .stakeYourText {
      width: 60%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .stakeYourImg {
      width: 40%;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 24px;
      }

      img {
        width: 100%;
      }
    }
  }

  .stakeIcon {
    svg {
      padding-right: 12px;
    }
  }

  .stakeBorderRemove {
    position: relative;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: 0;
    //   height: 100%;
    //   width: 1px;
    //   background-color: $input-border;
    // }
  }

  .InfoNeal {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $white-color;
    border-radius: 10px;
    padding: 40px 24px;
    margin-bottom: 48px;

    @media screen and (max-width: 767px) {
      margin-bottom: 32px;
      padding: 20px 16px;
    }

    .calculator {
      width: 100%;
    }

    .innerLeft {
      width: 48%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .titleInput {
        height: 48px;
        background: $white-color;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: $title-color;
        border: 1px solid $input-border;
        border-radius: 12px;
      }

      .ProgressBar {
        height: 4px;
        background: #f6f6f7;
        border-radius: 10px;
        margin: 24px 0;
        position: relative;

        &::after {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          left: 0;
          top: -5px;
          background-color: $main-color;
          content: "";
          position: absolute;
        }
      }

      .cryptoAmount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .textRight {
          display: block;
          text-align: right;
        }

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $input-label;
          margin-bottom: 12px;
        }
      }

      .mainTerms {
        margin-top: 32px;

        @media screen and (max-width: 767px) {
          margin-top: 25px;
        }

        span {
          margin-bottom: 16px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $input-label;
          display: inline-block;
          margin-bottom: 10px;
        }

        .terms {
          button {
            width: 101px;
            height: 44px;
            background: $white-color;
            border: 1px solid $input-border;
            border-radius: 10px;
            margin-right: 10px;

            @media screen and (max-width: 1200px) {
              margin-top: 10px;
            }

            @media screen and (max-width: 767px) {
              margin-right: 2px;
              width: 75px;
            }
          }
        }
      }
    }

    .innerRight {
      width: 48%;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 24px;
      }

      .cryptoAmount {
        margin-top: 24px;
      }

      .cryptoAmount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          width: 50%;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $input-label;
            margin-bottom: 12px;
          }
        }
      }

      .rewards {
        background: $bg-color;
        border-radius: 12px;
        padding: 24px 24px 32px;
        margin-bottom: 20px;

        @media screen and (max-width: 767px) {
          padding: 16px;
        }

        .blue {
          font-weight: 700;
          font-size: 28px;
          color: $main-color;

          @media screen and (max-width: 767px) {
            font-size: 22px;
          }
        }

        ul {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-bottom: 24px;
          border-bottom: 1px solid $input-border;

          @media screen and (max-width: 767px) {
            padding-bottom: 16px;
          }

          li {
            width: 50%;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: $input-label;
              margin-bottom: 12px;
            }
          }
        }
      }

      .learnMore {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $button-text-color;
        max-width: 108px;
        height: 32px;
        margin-left: auto;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: var(--dark-gray);
      }
    }

    strong {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }

      span {
        color: $input-label;
        font-size: 12px;
        font-weight: 500;

        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
  }

  .tokenFlex {
    column-gap: 12px;
    margin: 32px 0 48px;
    justify-content: space-evenly;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      row-gap: 8px;
      margin: 0 0 16px;
    }
  }

  .disabledBox {
    button {
      background-color: transparent;
      color: #9ca3af;
      border: 1px solid $input-border;
    }

    h3 {
      color: $black-color;
    }

    p {
      // opacity: 0.5;
    }

    span {
      opacity: 0;
    }

    strong {
      opacity: 0;
    }

    hr {
      display: none;
    }
  }

  .gridsystem {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 5px;
    align-items: center;
    margin-bottom: 50px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  .rightIcon {
    fill: $title-color;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

@import "../../../styles/variables";

.GetingStart {
  .gettingSection {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    .startedtitle {
      h2 {
        margin-top: 0;
      }
    }

    .connectWallet {
      width: 70%;
      background-color: $white-color;
      padding: 24px 24px;
      border-radius: 10px;

      @media screen and (max-width: $breakpoint-medium) {
        padding: 16px;
        width: 100%;
        margin-bottom: 16px;
      }

      .metamask {
        margin-top: 24px;

        img {
          width: 100%;
        }

        .destopImg {
          @media screen and (max-width: $breakpoint-medium) {
            display: none;
          }
        }

        .mobileImg {
          display: none;

          @media screen and (max-width: $breakpoint-medium) {
            display: block;
          }
        }
      }

      .metamaskTitle {
        h2 {
          margin-top: 24px;
          color: $title-color;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
        }

        p {
          color: $peragraph-text;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 140%;
        }
      }

      .nowthat {
        color: $peragraph-text;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        margin-top: 12px;
      }

      .installationBrowser {
        padding-bottom: 32px;
        border-bottom: 1px solid $input-border;
      }

      .helpfullArticle {
        text-align: center;
        margin-top: 32px;

        @media screen and (max-width: $breakpoint-small) {
          margin-top: 24px;
        }

        .buttonGroup {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 24px;

          button {
            width: 120px;
            height: 32px;
          }

          .yesButton {
            width: auto;
            margin-right: 10px;
          }

          .noButton {
            width: auto;
            margin-left: 10px;

            button {
              width: 120px;
              height: 32px;
            }
          }
        }

        .articleTitle {
          h2 {
            margin-top: 0;
            font-size: 16px;
            margin-bottom: 20px;

            @media screen and (max-width: $breakpoint-medium) {
              margin-bottom: 16px;
            }
          }
        }

        .feedbackReply {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: $title-color;
        }
      }
    }

    .articleSection {
      width: calc(30% - 24px);
      background-color: $white-color;
      padding: 24px;
      margin-left: 24px;
      border-radius: 10px;

      @media screen and (max-width: $breakpoint-medium) {
        padding: 16px;
        width: 100%;
        margin-left: 0;
      }

      .articleTitle {
        h2 {
          margin-top: 0;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }

      .connectLinks {
        .links {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em;
          color: $peragraph-text;
          display: block;
          margin-top: 16px;
        }

        .active {
          color: $title-color;
        }
      }
    }
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .submitBtn {
    width: 50%;
  }
}

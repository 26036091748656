@import "../../styles/variables";

.stakContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .stakMainDiv {
    width: 100%;
    max-width: 604px;
    background-color: $white-color;
    border-radius: 8px;
  }

  .cardWrapper {
    padding: 60px;
    margin: auto;
    @media screen and (max-width: $breakpoint-large) {
      padding: 30px;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: $title-color;
      margin-bottom: 28px;
    }

    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 3px;
      border-radius: 50px;
      border: 1px solid $input-border;
      overflow: hidden;
      background-color: #f6f6f7;
    }

    .max {
      border-left: 1px solid $input-border;
      padding-left: 10px;

      h4 {
        background-color: $white-color;
        border-radius: 30px;
        padding: 2px 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $color-yellow;
      }
    }
    input {
      outline: none;
      border: none;
      background-color: $white-color !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      padding-left: 0px;
    }

    .titleInputMobContainer {
      .titleInputMob {
        background: $white-color;
        border: 1px solid $input-border;
        border-radius: 12px;

        .titleInputMobTop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: $title-color;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: $main-color-70;
          }
        }

        .inputContainerMob {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 10px;
          color: $title-color;
          font-size: 18px;
        }
      }
    }

    .claim {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      margin-bottom: 16px;
      margin-top: 24px;
    }

    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-top: 39px;
    }

    .tokenDetail {
      background-color: #f9f7fc;
      padding: 24px 20px;
      border-radius: 12px;
      margin-top: 32px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: $main-color-70;
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $main-color;
      }

      .colorChange {
        color: $main-color !important;
      }
    }
  }
  .duration {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $main-color-70;
    margin-top: 16px;
  }
  .monthButtonParrent {
    row-gap: 4px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;
    .monthButton {
      margin-top: 0;

      @media screen and (max-width: $breakpoint-small) {
        margin-right: 4px;
      }
    }
  }
}

@media (max-width: 768px) {
  .stakContainer {
    .stakMainDiv {
      width: 100%;
    }

    .cardWrapper {
      padding: 16px;
    }

    .btnContainer {
      flex-direction: column-reverse;
    }
  }
}

@media (min-width: 768px) and (max-width: 1166px) {
  .stakMainDiv {
    width: 100% !important;
  }
}
.transation {
  text-align: center;
  button {
    max-width: 220px;
    margin: auto;
  }
}

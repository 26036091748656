@import "../../../styles/variables";
@import "../../../styles/mixins";

.EditSavingPlan {
  .timeline {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $peragraph-text;
    margin-top: 16px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    color: $title-color;
    margin-bottom: 32px;

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 20px;
      margin-bottom: 24px;
    }
  }

  .buttonGroup {
    margin-top: 40px;
    @include flexProperty;

    .cancel {
      width: 48%;

      @media screen and(max-width:$breakpoint-small) {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    .saveChange {
      width: 48%;

      @media screen and(max-width:$breakpoint-small) {
        width: 100%;
      }
    }
  }

  .flexButton {
    margin-bottom: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0;

    .investButton {
      button {
        @media screen and (max-width: $breakpoint-medium) {
          // padding: 11px 20px 11px 20px;
        }
      }
    }
  }
}

@import "../../styles/variables";
@import "../../styles/mixins";

.SavingPlan {
  background: $white-color;
  border-radius: 10px;
  max-width: 354px;
  min-height: 360px;
  padding: 32px;
  width: 100%;
  text-align: center;

  @media screen and (max-width: $breakpoint-small) {
    padding: 24px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: $title-color;
    margin-bottom: 12px;
  }

  .link {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
    color: $title-color;
    margin-bottom: 24px;
    display: block;
  }

  .content {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $peragraph-text;
  }

  .divide {
    border-left: 1px solid $input-border;
    height: 50px;
  }

  .boxDetials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 30px;
    gap: 3px;

    @media screen and (max-width: $breakpoint-large) {
      margin: 16px 30px;
    }

    @media screen and (max-width: $breakpoint-small) {
      margin: 16px 50px;
    }

    .boxItemsec {
      width: 50%;
      text-align: center !important;
      // border-left: 1px solid $input-border;
    }

    .boxItems {
      width: 50%;
      text-align: center;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $input-placeholder;
        white-space: nowrap;
      }

      strong {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $title-color;
      }
    }
  }

  .boxImg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .plusicon {
      svg {
        margin: 0 18px;
      }
    }

    @media (min-width: $breakpoint-large) and (max-width: 1100px) {
      flex-direction: column;
    }
  }

  .savingBoxbutton {
    height: 40px;
  }

  .editCancelPlan {
    @include flexProperty;

    .editplan {
      width: 48%;
    }

    .cancelPlan {
      width: 48%;
    }
  }
}

.editPopup {
  text-align: center !important;

  button {
    max-width: 220px;
    margin: auto;

    @media screen and(max-width:$breakpoint-small) {
      max-width: 100%;
    }
  }
}

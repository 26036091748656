@import "../../../styles/variables";

.myInvestPlan {
  .tokenHeader {
    .customAccordion {
      box-shadow: none;
      margin-bottom: 20px;
      background: $white-color;
      border-radius: 10px;
      padding: 13.5px 24px;
      flex-wrap: wrap;
      @media screen and (max-width: $breakpoint-small) {
        padding: 12px;
      }
      .content {
        background-color: $table-row-bg;
        padding: 24px;
        @media screen and (max-width: $breakpoint-small) {
          padding: 12px;
        }
        .MonthlyTitle {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: $title-color;
          margin-bottom: 16px;
        }
        .contentTitle {
          h2 {
            margin-top: 0;
            font-size: 16px;
            @media screen and (max-width: $breakpoint-small) {
              // padding: 15px;
            }
          }
        }
        .monthlyInvesting {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          flex-direction: row;
          border-bottom: 1px solid $input-border;
          padding-bottom: 20px;
          margin-bottom: 24px;
          .monthlyItems {
            width: 170px;
            @media screen and (max-width: $breakpoint-small) {
              width: 50%;
            }
            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: $peragraph-text;
              margin-bottom: 12px;
              @media screen and (max-width: $breakpoint-small) {
                margin-bottom: 4px;
              }
            }
            strong {
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 21px;
              color: $title-color;
              @media screen and (max-width: $breakpoint-small) {
                font-size: 14px;
                margin-bottom: 8px;
              }
            }
          }
        }

        .subscriptionTitle {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: $title-color;
          margin-bottom: 4px;
        }
        .subscriptonText {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $peragraph-text;
          margin-top: 12px;
        }
      }
    }
    .token {
      display: flex;
      align-items: center;
      flex-wrap: wrap !important;
      justify-content: space-between;

      .tokenIcon {
        display: flex;
        align-items: center;
        width: 50%;
        justify-content: flex-start;
        @media screen and (max-width: $breakpoint-medium) {
          width: 100%;
        }
        .star {
          margin-left: 8px;
          @media screen and (max-width: $breakpoint-small) {
            margin-left: 4px;
          }
        }
        .tokenTitle {
          padding-left: 20px;
          h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: $title-color;
            @media screen and (max-width: $breakpoint-small) {
              font-size: 16px;
            }
          }
          .tokenLink {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 140%;
            color: $title-color;
            margin-top: 8px;
            display: block;
            @media screen and (max-width: $breakpoint-small) {
              font-size: 12px;
            }
          }
        }
      }
      .stakBtn {
        display: flex;
        align-items: center;
        width: 50%;
        justify-content: flex-end;
        @media screen and (max-width: $breakpoint-medium) {
          width: 100%;
          justify-content: flex-start;
          margin-top: 24px;
        }
        .editPlan {
          max-width: 140px;
          height: 40px;
          margin-right: 32px;
          @media screen and (max-width: $breakpoint-large) {
            margin-right: 16px;
          }
          @media screen and (max-width: $breakpoint-small) {
            font-size: 14px;
            height: 32px;
          }
        }
        .cancelPlan {
          margin-right: 32px;
          @media screen and (max-width: $breakpoint-large) {
            margin-right: 16px;
          }
          .CancelPlanLink {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: $color-yellow;
            border: 0;
            outline: none;
            background-color: transparent;
            position: relative;
            z-index: 2;
            cursor: pointer;
            @media screen and (max-width: $breakpoint-small) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.editPopup {
  text-align: center !important;
  button {
    max-width: 220px;
    margin: auto;
    @media screen and(max-width:$breakpoint-small) {
      max-width: 100%;
    }
  }
}

@import "../../styles/variables";

.notification {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  cursor: pointer;

  .notificationText {
    margin-left: 16px;
    margin-bottom: 16px;

    .subTitle {
      margin-top: 0;
      color: $title-color;
      font-size: 16px;
      margin-bottom: 4px !important;
      font-weight: 500;
    }

    .content {
      font-weight: 400;
      font-size: 15px;
      line-height: $peragraph-text;
    }

    .days {
      color: $tableheader;
      font-weight: 400;
      font-size: 15px;
    }
  }
}

.wrapper {
  cursor: pointer;
}

.notificationPopUp {
  max-width: 440px !important;

  @media screen and (min-width: $breakpoint-small) {
    padding: 0;
  }

  .notificationInput {
    position: relative;
    margin-bottom: 24px;

    .clearAll {
      position: absolute;
      right: 10px;
      top: 12px;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      background: transparent;
      border: 0;
      outline: none;
      font-weight: 700;
      color: $color-yellow;
      z-index: 9;
      cursor: pointer;
    }
  }

  .crossIcon {
    position: absolute;
    top: 22px;
    right: 22px;
    cursor: pointer;
  }
}

.unreadNotification {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: -2px;
    width: 10px;
    height: 10px;
    background: $color-yellow;
    border-radius: 50px;
    border: 1px solid $white-color;
  }
}

@import "../../../styles/variables.scss";

.container {
  // padding: 20px;
  text-align: center;
  min-width: 500px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: $title-color;
    margin-bottom: 0px !important;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $input-label;
    margin-bottom: 32px;
  }

  .imgContainer {
    width: 100%;
    height: 252px;
    background-color: $input-border;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    cursor: pointer;
    position: relative;
    overflow: scroll;

    img {
      position: relative !important;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    input {
      display: none;
    }

    span {
      color: $input-label;
      font-size: 25px;
    }
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}

@media (max-width: $breakpoint-medium) {
  .container {
    min-width: 100%;

    .imgContainer {
      width: 100%;

      span {
        font-size: 15px;
      }
    }

    .btnContainer {
      flex-direction: column;
    }
  }
}

@import "../../../styles/variables";
@import "../../../styles/mixins";
.SubscribePlan {
  display: flex;
  align-items: flex-start;
  @media screen and (max-width: $breakpoint-medium) {
    flex-wrap: wrap;
  }
  .investPlanStepper {
    max-width: 756px;
    width: 100%;
    position: relative;
    @media screen and (max-width: $breakpoint-medium) {
      max-width: 100%;
    }
    .investPlanDetails {
      padding: 48px 60px;
      min-height: 961px;
      background-color: $color-white;
      margin-top: 35px;
      border-radius: 10px;
      @media screen and (max-width: $breakpoint-large) {
        padding: 24px;
      }
      @media screen and (max-width: $breakpoint-medium) {
        padding: 16px;
      }
      .ConfirmContinuebutton {
        max-width: 248px;
        margin-top: 32px;
        @media screen and (max-width: $breakpoint-small) {
          max-width: 100%;
          margin-top: 24px;
        }
      }
    }

    .avtiveIcon {
      fill: $title-color;
      border: 2px solid $title-color;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 4px;
    }
    .iconText {
      fill: $color-white;
    }
    .completed {
      fill: $title-color;
    }
  }
  .investQuicllinks {
    background-color: $white-color !important;
    .QuickTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      margin-bottom: 20px;
    }
    .QuickLinks {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: $peragraph-text;
      margin-bottom: 16px;
      display: block;
    }
  }
  .investPlamSummery {
    max-width: 330px;
    width: 100%;
    background-color: #eee9f6;
    padding: 32px 24px;
    margin-top: 75px;
    margin-left: 24px;
    border-radius: 10px;
    @media screen and (max-width: $breakpoint-large) {
      margin-left: 12px;
      padding: 16px 12px;
    }
    @media screen and (max-width: $breakpoint-medium) {
      max-width: 100%;
      padding: 16px;
      margin-top: 16px;
      margin-left: 0;
    }
    .summeryTable {
      width: 100%;
      margin-top: 20px;
      th {
        display: table-cell;
      }
      .blue {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        letter-spacing: 0.02em;
        color: $color-blue;
      }
      .Investing {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $peragraph-text;
        text-align: left;
      }
      .InvestingTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: $peragraph-text;
        text-align: right;
      }
    }
    .tableTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      @media screen and (max-width: $breakpoint-small) {
        font-size: 15px;
      }
    }
  }
}

@import "../../../styles/_variables";
@import "../../../styles/mixins";

.loyalty {
  margin-top: 32px;

  @media screen and (max-width: $breakpoint-small) {
    margin-top: 24px;
  }

  .referFridnd {
    background-color: $white-color;
    padding: 32px 24px 40px;
    border-radius: 10px;
    margin-bottom: 24px;
  }

  .Referral {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;

    @media screen and (max-width: 1299px) {
      align-items: flex-start;
    }

    .ReferralP {
      width: 58%;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: $input-placeholder;
      padding-bottom: 10px;

      @media screen and (max-width: 1299px) {
        padding-bottom: 0;
      }

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        padding-bottom: 0;
      }
    }

    .referralInputColor {
      color: $title-color;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .ReferralInput {
      width: 40%;
      position: relative;

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        margin-bottom: 16px;
      }

      svg {
        position: absolute;
        right: 22px;
        bottom: 28px;
        cursor: pointer !important;
      }

      input {
        padding-right: 48px;
        // background: $light-gray;
        // border: 1px solid $input-border;
        // border-radius: 10px;
        // outline: none;
        // margin-top: 12px;
        // color: $color-blue;
        // padding-right: 50px;
      }
    }
  }

  .rewards {
    display: flex;
    justify-content: flex-end;
    margin-top: -20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $title-color;

    @media screen and (max-width: $breakpoint-allmobile) {
      margin-top: 16px;
      justify-content: flex-start;
    }
  }

  .receiveloyalty {
    margin-bottom: 24px;
    margin-top: 30px;
  }

  .receiveloyaltyPoint {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 24px;
    row-gap: 12px;

    @media screen and(max-width:$breakpoint-small) {
      grid-template-columns: auto;
    }
  }

  .gridsystem {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 5px;
    align-items: center;
    margin-bottom: 50px;

    @media screen and (max-width: $breakpoint-allmobile) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  .rightIcon {
    @media screen and (max-width: $breakpoint-allmobile) {
      display: none;
    }
  }

  .tableHeader {
    border: 0;
    @include fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $btn-disabled-color;
  }

  .tablecellData {
    @include fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $title-color;
    border: 0;
    padding: 22px 16px;
    background-color: $white-color;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .tableceReward {
    font-weight: 700;
  }

  .tablecePoints {
    color: $color-blue;
  }

  .dataTable {
    margin-bottom: 20px;
  }
}

@import "../../styles/variables";

.not_found {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
  flex-wrap: wrap;
  align-content: center;

  .wrapper {
    max-width: 300px;
    width: 300px;
    text-align: center;
  }

  .tyrAgain {
    width: 100%;
    max-width: 150px;
    font-size: 14px;
  }

  h1 {
    font-size: 80px;
  }

  .title {
    h2 {
      margin: -15px 0px 10px 0px;
      font-size: 30px;
    }
  }
}
.alloMobContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background:var(--table-row-bg);
    border-radius: 12px;
    margin-bottom: 10px;
    padding: 16px 12px;

    .alloMobInner {
        width: 50%;
        text-align: left;
        margin-bottom: 12px;

        // background-color: orange;
        p {

            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--dark-gray);
        }

        h5 {

            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: var(--text-dark);
            margin-bottom: 4px;
        }

        h6 {

            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--peragraph-text);
        }
    }
}

.pContainer {
    display: flex;
    align-items: center;
    margin-top: 9px;

    .progress {
        background-color: #ECE8F2;
        ;
        width: 100px;
        height: 10px;
        border-radius: 20px;
    }

    .progressInner {
        background-color: #7873F9;
        width: 90%;
        height: 10px;
        border-radius: 20px;
    }
}

.flex {
    display: flex;
    align-items: center;
    gap: 6px
}

.change {
    display: flex;
    align-items: center;
    gap: 7.6px;

    .blue {
        color: var(--color-blue) !important;
    }
}
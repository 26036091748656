@import "../../styles/variables";
.link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: $peragraph-text;
  margin-bottom: 60px;
  margin-top: 24px;
  a {
    color: $color-yellow;
    font-weight: 700;
  }
}

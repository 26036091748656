@import "../../../styles/variables.scss";

// $breakpoint-medium
.children {
  padding: 22px;
  position: relative;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

@media (max-width: $breakpoint-small) {
  .children {
    padding: 40px 0px 30px 0px;
  }
}
@import "../../../styles/variables";

.about {
  flex: 1;
  border-radius: 10px;
  background-color: $main-color-20;
  padding: 21px 24px;

  .aboutTop {
    display: flex;
    gap: 11px;
    margin-bottom: 19px;

    .h4 {
      color: $title-color;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  p {
    color: $title-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@import "../../styles/variables";
.LearnMore {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $input-label;
    margin-bottom: 32px;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 26px;
    color: $title-color;
    margin-bottom: 24px;
    @media screen and (max-width: $breakpoint-medium) {
      font-size: 18px;
    }
  }
  .icon {
    text-align: center;
    svg {
      margin-bottom: 35px;
      display: inline-block;
      @media screen and (max-width: $breakpoint-medium) {
        margin-bottom: 26px;
      }
    }
  }
}

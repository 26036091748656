@import "../../../styles/variables";

.mywalletCard {
  padding: 16px;
  background-color: $white-color;
  border-radius: 10px;

  @media screen and (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    padding: 12px;
  }

  .buttonGroups {
    justify-content: flex-end;

    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    .addButton {
      height: 32px;
      max-width: 146px;
      width: 100%;
      margin-right: 12px;
      font-size: 14px;

      @media screen and (max-width: $breakpoint-small) {
        margin-right: 8px;
        max-width: 50%;
      }
    }

    .withdraw {
      height: 32px;
      max-width: 146px;
      width: 100%;
      font-size: 14px;

      @media screen and (max-width: $breakpoint-small) {
        max-width: 50%;
      }
    }
  }

  .walletBalanceIcon {
    width: 40px;
    height: 40px;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: $breakpoint-small) {
      margin-right: 12px;
    }
  }

  .walletBalance {
    font-size: 12px;
    color: $peragraph-text;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 4px;
    }
  }

  .wallerPrice {
    font-size: 24px;
    color: $title-color;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;

    @media screen and (max-width: $breakpoint-small) {
      font-size: 20px;
    }
  }

  .tokenPrice {
    font-size: 14px;
    color: $title-color;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .cardCentertext {
    justify-content: space-between;

    .centerText {
      width: 50%;
    }

    @media screen and (max-width: $breakpoint-small) {
      justify-content: flex-start;
      border-top: 1px solid $input-border;
      padding-top: 20px;

      .centerText {
        width: 50%;
      }
    }
  }

  .buy {
    max-width: 110px;
    width: 100%;
    margin-right: 16px;
    height: 40px;

    @media screen and (max-width: $breakpoint-small) {
      margin-right: 12px;
      max-width: 50%;
      height: 32px;
      font-size: 14px;
    }
  }

  .sell {
    max-width: 110px;
    width: 100%;
    height: 40px;

    @media screen and (max-width: $breakpoint-small) {
      max-width: 50%;
      height: 32px;
      font-size: 14px;
    }
  }
}

.walletSpace {
  margin-top: 16px;
}

@import "../../../styles/variables";
.accordionHeader {
  display: flex;
  align-items: center;
  text-align: left;
  width: 95%;
  @media screen and (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
  }
  .title {
    max-width: 101px;
    width: 100%;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 40%;
    }

    h4 {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.28px;
      color: $title-color;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      color: $peragraph-text;
      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 12px;
      }
    }
  }
  .ProgressBar {
    max-width: 195px;
    width: 100%;
    margin-left: 50px;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 60%;
      margin-left: 0px;
      margin-bottom: 12px;
    }
  }
  .headerValue {
    max-width: 100px;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
    color: $title-color;
    margin-left: 10px;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 40%;
      margin-left: 0;
    }
  }
  .selectValue {
    max-width: 120px;
    width: 100%;
    font-size: 14px;
    color: $title-color;
    margin-left: 10px;
    font-weight: 500;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 100%;
      margin-left: 0;
    }
  }
  .serialNumber {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
    color: $title-color;
    margin-right: 29px;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 60%;
      margin-right: 0;
      width: 100%;
    }
  }
  .breackpoint {
    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 4px;
    }
  }
}

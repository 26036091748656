@import "../../styles/variables";

.backBtn {
  display: flex;
  align-items: center;
  border: 0;
  letter-spacing: 0.02em;
  color: $color-yellow;
  font-weight: 700;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;

  @media screen and (min-width: $breakpoint-xsmall) {
    position: static;
    margin-bottom: 22px;
  }
  @media screen and (min-width: $breakpoint-medium) {
    position: fixed;
    z-index: 9;
    top: 40px;
    left: 300px;
  }
}

@import "../../styles/variables.scss";

.personalInfo {
  // max-width: 900px;
  padding: 20px;

  .flexBox {
    gap: 20px;
  }

  .flex {
    @media (max-width: $breakpoint-small) {
      flex-direction: column;
    }
  }

  .form,
  .socialMulti {
    @media (max-width: $breakpoint-small) {
      padding: 10px !important;
    }
  }
}

.addBtn {
  width: 30px;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
  z-index: 1;
}

.field {
  width: 100%;
}

.btnField {
  display: flex;
  align-items: center;
  gap: 10px;
}

.disabledPoint {
  pointer-events: none;
}

.crossBtn {
  width: 30px;
  height: 30px;
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dateContainer {
  width: 100%;
  margin-top: -2px;
}

.showEmail {
  display: block !important;
}

.hideEmail {
  display: none !important;
}

.flexSingle {
  width: 49%;
}

.walletBtn {
  width: 235px;
  height: 48px;
  border: 1px solid $title-color;
  border-radius: 20px;
  color: $title-color;
  padding: 6px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: $btn-text-color;
  cursor: pointer;
  margin-bottom: 80px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continueBtn {
  width: 242px;
}

.header {
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: $title-color;
  }
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $input-label;
}

h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: $main-color;
  margin-bottom: 10px !important;
  margin-top: 24px;
  margin-bottom: 16px;
}

.skipText {
  display: none;
}

.skip {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: $link-color;
    cursor: pointer;
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 100px;
}

@media (max-width: $breakpoint-small) {
  .personalInfo {
    padding: 5px;

    .header {
      margin-left: 10px;
    }

    .walletBtn {
      width: 100%;
      margin-bottom: 20px;
    }

    .continueBtn {
      width: 100%;
    }

    .btnContainer {
      padding: 0px 10px;
    }

    .loc {
      margin-left: 10px;
    }

    .flexSingle {
      width: 100%;
    }

    .skip {
      h5 {
        display: none;
      }
    }

    .skipText {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-decoration-line: underline;
      color: $link-color;
      cursor: pointer;
      padding-bottom: 50px;
    }
  }
}

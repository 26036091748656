@import "../../../styles/variables";
.stbleAmountMain {
  .stbleAmount {
    background-color: $white-color;
    max-width: 604px;
    width: 100%;
    border-radius: 10px;
    margin: auto;
    padding: 48px 60px;
    @media screen and (max-width: $breakpoint-large) {
      padding: 32px;
    }
    @media screen and (max-width: $breakpoint-small) {
      padding: 16px;
    }
    .coinDetail {
      position: relative;
      .exchangeIcon {
        position: absolute;
        top: 40%;
        right: 45%;
      }
    }
    .chooseToken {
      background-color: $gray-Bg-Color;
      padding: 12px;
      border-radius: 12px;
      justify-content: space-between;
      margin-bottom: 20px;
      @media screen and (max-width: $breakpoint-small) {
        flex-wrap: nowrap;
        flex-direction: column;
        row-gap: 12px;
      }

      .exchangeIcon {
        margin: 0 8px;
        transform: rotate(90deg);
        @media screen and (max-width: $breakpoint-small) {
          display: none;
        }
      }
    }
    .confirmButton {
      margin-top: 40px;
      @media screen and (max-width: $breakpoint-small) {
        margin-top: 32px;
      }
    }
  }
  .faqSection {
    background-color: $gray-Bg-Color;
    padding: 24px;
    border-radius: 10px;
    margin-top: 32px;
    @media screen and (max-width: $breakpoint-small) {
      padding: 12px 24px;
    }
    .swapLink {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.32px;
      color: $peragraph-text;
      display: block;
    }
    .swapLinkSpace {
      margin-bottom: 16px;
      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 8px;
      }
    }
    .faqtitle {
      @media screen and (max-width: $breakpoint-small) {
        h2 {
          margin-top: 0;
          margin-bottom: 8px;
        }
      }
    }
  }
  .priceImpact {
    border: 1px solid $input-border;
    border-radius: 12px;
    padding: 0 16px 16px 16px;
    background-color: $light-gray;
    .priceImpactflexbox {
      justify-content: space-between;
      margin-top: 16px;
      .priceValue {
        font-size: 15px;
        color: $title-color;
        font-weight: 700;
        line-height: 140%;
      }
      .receivedValue {
        font-size: 15px;
        color: $title-color;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
}
.swapPoptitle {
  h2 {
    margin-top: 0;
  }
}
.gotItButton {
  margin-top: 40px;
  @media screen and (max-width: $breakpoint-small) {
    margin-top: 32px;
  }
}
.choosechainFlexbox {
  flex-wrap: wrap;
  row-gap: 0;
}
.selectPopupTitle {
  h2 {
    margin-top: 0;
    font-size: 28px;
    margin-bottom: 24px;
    @media screen and (max-width: $breakpoint-small) {
      font-size: 20px;
    }
  }
}
.chooseTokenflexbox {
  padding-bottom: 8px;
  border-bottom: 1px solid $input-border;
  margin-bottom: 24px;
}
.transactionPopup {
  text-align: center;
  button {
    max-width: 220px;
    margin: auto;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 100%;
    }
  }
}

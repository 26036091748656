@import "../../styles/variables.scss";

.back {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 50px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: var(--color-orange);
  }
}

.myTokenContainer {
  width: 100%;
  height: 100%;
  padding: 30px;

  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    color: var(--text-dark);
    margin-bottom: 24px;
  }

  .wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 20px;

    .cardLeft {
      width: 70%;

      .innerCard {
        margin-bottom: 20px;

        .statContainer {
          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: var(--text-dark);
            text-align: start;
          }

          .statDEtail {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .statItem {
              width: 25%;
              padding: 20px 0px;
              text-align: start;

              p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                align-items: center;
                color: var(--dark-gray);
              }

              h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                color: var(--text-dark);
                margin-top: 12px;
              }
            }
          }
        }

        .cardInner {
          .svg {
            width: 100%;
          }

          .cardInnerContent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cardInnerContentLeft {
              width: 100%;
              text-align: left;
              margin-bottom: 24px;

              .priceMob {
                display: none;

                .priceContainerMob {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  h6 {
                    margin: 0px;
                  }
                }
              }

              .priceContainer {
                display: flex;
                align-items: center;
                gap: 5px;

                h3 {
                  font-style: normal;
                  font-weight: 800;
                  font-size: 40px;
                  line-height: 53px;
                  letter-spacing: 0.02em;
                  color: var(--text-dark);
                  margin-bottom: 6px;
                }

                span {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 21px;
                  letter-spacing: 0.02em;
                  color: var(--text-dark);
                  margin-top: 10px;
                }
              }

              p {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: var(--peragraph-text);
                margin-bottom: 8px;
              }

              h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: var(--color-blue);
                margin-bottom: 20px;
              }
            }

            .cardInnerContentRight {
              display: flex;
              align-items: center;
              gap: 20px;

              p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: $title-color;
              }

              .active {
                width: 40px;
                height: 22px;
                background: #1e1e1e;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white-color;
              }
            }
          }
        }

        .innerCardAllocation {
          .allocationHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .allocationHeaderHead {
              width: 20%;
              text-align: left;

              p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: var(--dark-gray);
                margin-bottom: 10px;
              }
            }
          }

          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            display: flex;
            align-items: flex-start;
            color: var(--text-dark);
            margin-bottom: 16px;
          }

          table {
            width: 100%;
            border-spacing: 0px;

            th {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: var(--dark-gray);
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .cardRight {
      width: 30%;

      .coinDetail {
        position: relative;

        .exchangeIcon {
          position: absolute;
          top: 40%;
          right: 45%;
        }
      }

      .btn {
        margin-top: 32px;
      }
    }

    .swapContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $title-color;
      }
    }

    .amountContainer {
      background: var(--light-gray);
      border: 1px solid var(--input-border);
      border-radius: 12px;
      margin-bottom: 12px;
      padding: 12px;

      .amountTop {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $title-color;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: var(--input-label);
        }
      }

      .amountBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 29px;
          color: #b5b8cb;
        }

        .amountBottomRight {
          display: flex;
          align-items: center;

          .coin {
            width: 96px;
            height: 32px;
            background: var(--white-color);
            border: 1px solid #e8e1f6;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-left: 12px;

            p {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              align-items: center;
              letter-spacing: 0.02em;
              color: #4e307a;
            }
          }

          .max {
            background: var(--white-color);
            border-radius: 24px;
            width: 49px;
            height: 24px;

            span {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.02em;
              color: var(--color-orange);
            }
          }
        }
      }
    }

    .allocationRow {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: $table-row-bg;
      border-radius: 12px;
      margin-bottom: 12px;

      .allocationRowCell {
        width: 20%;
        text-align: left;
        padding: 16px 0px;

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: var(--text-dark);
          // padding: 16px 24px;
          // text-align: left;
        }

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: var(--peragraph-text);
        }
      }

      .changeUsd {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
      }
    }

    .change {
      display: flex;
      align-items: center;
      gap: 7.6px;

      .blue {
        color: var(--color-blue) !important;
      }
    }

    .allocationMob {
      display: none;

      .alloMobContainer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background: var(--table-row-bg);
        border-radius: 12px;
        margin-bottom: 10px;
        padding: 16px 12px;

        .alloMobInner {
          width: 50%;
          text-align: left;
          margin-bottom: 12px;

          // background-color: orange;
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--dark-gray);
          }

          h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: var(--text-dark);
            margin-bottom: 4px;
          }

          h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--peragraph-text);
          }
        }
      }
    }

    .allocationTitle {
      text-align: start;
      margin-bottom: 16px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: var(--text-dark);
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .pos {
    // width: 10% !important;
    margin-left: 15px;
  }

  .pContainer {
    display: flex;
    align-items: center;
    margin-top: 9px;

    .progress {
      background-color: #ece8f2;
      width: 100px;
      height: 10px;
      border-radius: 20px;
    }

    .progressInner {
      background-color: $main-color;
      width: 90%;
      height: 10px;
      border-radius: 20px;
    }
  }
}

@media (max-width: 1150px) {
  .wrapper {
    flex-direction: column;

    .cardLeft {
      width: 100% !important;
    }

    .cardRight {
      width: 100% !important;
    }

    .cardInnerContent {
      flex-direction: column;
    }

    .statItem {
      width: 50% !important;
    }
  }

  .myTokenContainer {
    padding: 0px !important;
  }

  .cardInnerContent {
    align-items: flex-start !important;
  }
}

@media (max-width: $breakpoint-medium) {
  .innerCardAllocation {
    display: none !important;
  }

  .allocationMob {
    display: block !important;
  }

  .priceContainer {
    span {
      display: none !important;
    }
  }

  .priceMob {
    display: block !important;
  }

  .rate {
    display: none !important;
  }

  .cardInnerContent {
    .cardInnerContentRight {
      width: 100% !important;
      justify-content: inherit !important;
    }
  }

  .priceContainer {
    flex-direction: column;
    gap: 0px;
    align-items: baseline !important;

    span {
      margin-top: -10px !important;
    }
  }
}

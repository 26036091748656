@import "../../../styles/variables.scss";

.index {
    .title {
        margin-bottom: 16px;
    }

    .indexContent {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 32px;

        .indexContentLeft {
            width: 70%;

            p {

                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 140%;
                color: var(--peragraph-text);
                margin-bottom: 16px;
            }
        }

        .indexContentRight {
            width: 30%;
            margin-left: 50px;

            h3 {

                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                color: var(--dark-blue);
            }

            p {

                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.02em;
                color: var(--peragraph-text);
                margin-top: 20px;
                margin-bottom: 16px;
            }
        }
    }

    .buySell {
        margin-bottom: 62px;

        h2 {

            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: var(--text-dark);
            margin-bottom: 16px;
        }

        p {

            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            color: var(--peragraph-text);

        }
    }

    .cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .buyContainer {
            margin-bottom: 40px;
        }

        .cardInner {
            flex: 1;
            position: relative;
            // height: 421px;

            img {
                position: absolute;
                top: -50px;
                width: 100%;
                left: 0px;
                height: 200px;
            }

            .buyContent {
                padding-top: 175px;

                h3 {

                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                    color: var(--text-dark);
                }

                p {

                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: var(--peragraph-text);
                    margin-top: 12px;

                }

                .rate {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    gap: 20px;
                    margin-top: 24px;
                    margin-bottom: 32px;

                    h6 {

                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        align-items: center;
                        text-align: center;
                        color: var(--dark-gray);
                    }

                    h4 {

                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: center;
                        letter-spacing: 0.02em;
                        color: var(--dark-blue);
                    }

                    .rateLeft {}

                    .divide {
                        width: 1px;
                        height: 50px;
                        background-color: var(--input-border);
                    }

                    rateRight {}
                }

                .btnContainer {
                    display: flex;
                    align-items: center;
                    gap: 20px
                }
            }
        }
    }

    .disabled {
        opacity: 0.5;
    }
}

@media (max-width: $breakpoint-medium) {
    .index {
        .indexContent {
            flex-direction: column;
            align-items: flex-start !important;

            .indexContentLeft {
                width: 100%;
            }

            .indexContentRight {
                width: 100%;
                margin-left: 0px !important;

                p {
                    margin-top: 8px !important;
                    margin-bottom: 8px !important;
                }
            }
        }

        .cards {
            flex-direction: column;
            margin-bottom: 50px;
        }
    }
}
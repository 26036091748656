@import "../../styles/variables.scss";

.tab {
  background-color: #f6f6f7;
  width: 100%;
  border-radius: 50px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $input-label;
    padding: 9px;
  }
}

.active {
  background-color: $color-white !important;
  border: none;

  p {
    color: $title-color !important;
  }
}

@import "../../styles/variables.scss";

input:placeholder {
  color: $input-placeholder;
}

.googleIcon {
  padding-right: 8px;
}

.bg {
  height: 100vh;
  position: relative;
  background-color: $main-color;

  // background: linear-gradient(
  //   140.74deg,
  //   #f8dce0 2.62%,
  //   #f5dde7 13.85%,
  //   #efdeef 25.74%,
  //   #ecdff5 37.06%,
  //   #e6e0fe 48.81%,
  //   #e9dfff 58.88%,
  //   #ecdcfd 69.22%,
  //   #efdafc 79.71%,
  //   #f3d8fb 89.08%,
  //   #f5d6fa 98.97%
  // );

  @media screen and (max-width: $breakpoint-medium) {
    height: auto;
  }

  .logoContainer {
    position: absolute;
    top: 56px;
    right: 96px;
    z-index: 9;

    svg {
      width: 40px;
      height: 47px;
    }
  }
}

.logo {
  padding-top: 54px;
  padding-left: 75px;
  display: inline-block;

  @media screen and (max-width: $breakpoint-medium) {
    padding-top: 22px;
    padding-left: 25px;
  }
}

form {
  padding: 0 !important;
  width: 100% !important;
}

.signup_page {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: center;
  }

  .create {
    text-align: center;
    color: $main-color;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 8px;
    }
  }

  .createContent {
    text-align: center;
  }

  .pro_tip {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: $main-color-70;
    font-style: italic;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    color: $title-color;
    margin-bottom: 16px;

    @media screen and (max-width: 992px) {
      font-size: 25px;
      text-align: center;
    }

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 22px;
    }
  }

  h6 {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $title-color;
  }

  .sign_form {
    max-width: 628px;
    width: 100%;
    background: $white-color;
    border: 1px solid #e8e1f6;
    border-radius: 40px 0px 0px 40px;
    padding: 56px 96px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    overflow-y: auto;

    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: $input-label;
      text-align: center;
    }

    @media screen and (max-width: $breakpoint-medium) {
      border-radius: 25px 24px 0px 0px;
      width: 100%;
      position: static;
      border: 0;
      max-width: 100%;
      padding: 20px;
      height: auto;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: 24px 16px !important;
    }

    .create_ac {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 16px;
      margin-top: 32px;
      @media screen and (max-width: $breakpoint-small) {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
      }
    }

    .input_field {
      display: flex;
      flex-direction: column;
      height: 145px;
      background: $table-row-bg;
      border-radius: 12px;
      width: 100%;
      text-align: left;
      position: relative;
      justify-content: flex-start;
      overflow: hidden;
      color: $main-color-70;

      .step_card_header {
        width: 100%;
        height: 36px;
        max-height: 36px;
        background-color: $main-color-10;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;

        .step_card_header_left {
          margin-right: 2px;
          color: $main-color-70;
        }
      }

      .step_card_content {
        display: flex;
        flex-direction: column;
        padding: 16px;
        p {
          svg {
            height: 40px;
            width: 40px;
          }
        }
        .step_card_title {
          margin-top: 10px;
          color: $main-color-70;
        }

        @media screen and (max-width: $breakpoint-small) {
          flex-direction: row;
          gap: 16px;
        }
      }
      .input_field_left {
        display: flex;
        align-items: center;
        height: 100%;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.02em;
          color: $peragraph-text;

          @media screen and (max-width: $breakpoint-small) {
            font-size: 15px;
          }
        }

        .input_field_left_text {
          height: 100%;
          width: 44px;
          background-color: $main-color;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: $color-white;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 8px;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $main-color-70;
        }
      }

      img {
        position: absolute;
        left: 15px;
      }

      .icon {
        // position: absolute;
        // left: 15px;

        margin: 0px 12px 0px 16px;

        @media screen and (max-width: $breakpoint-large) {
          margin: 0 12px !important;
        }

        @media screen and (max-width: $breakpoint-small) {
          height: 32px;
          width: 32px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      input {
        width: 100%;
        background: transparent;
        border: 0;
        outline: 0;
        padding-left: 65px;
        height: 64px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $input-label;
      }
    }

    .verifyBtn {
      margin: 32px 0;
    }

    .verify {
      text-align: center;

      button {
        @media screen and (max-width: $breakpoint-medium) {
          margin: 30px 0;
        }
      }

      a {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: $color-yellow;
        text-decoration: underline;
      }
    }
  }
}

// login page css

.signup_page {
  .input_group {
    margin-bottom: 12px;

    label {
      display: block;
      text-align: left;
    }

    input {
      height: 48px;
      background: $light-gray;
      border: 1px solid $input-border;
      border-radius: 12px;
      width: 100%;
      padding: 14px 16px;
    }

    button {
      box-sizing: border-box;
      max-width: 436px;
      height: 48px;
      width: 100%;
      border: 1px solid $title-color;
      border-radius: 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #4e307a;
      background: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      @media screen and (max-width: 7676px) {
        max-width: 100%;
      }

      span {
        padding-right: 8px;
        height: 20px;
      }
    }
  }

  .input_checkbox {
    margin: 25px 0 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: $breakpoint-medium) {
      margin: 20px 0;
    }

    input {
      background: $input-border;
      width: 20px;
      height: 20px;
      margin: 0;
    }

    .checklabel {
      padding-left: 12px;
      line-height: normal;
    }

    span {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: $input-label;
    }
  }

  .or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
    color: $title-color;

    @media screen and (max-width: $breakpoint-medium) {
      margin: 20px 0;
    }

    span {
      height: 1px;
      width: 45%;
      background: $input-border;
    }
  }

  .registered {
    margin-top: 37px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: $input-label;

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: $input-label;
      }

      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: $color-yellow;
      }
    }
  }

  .sign_img {
    width: 60%;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;

    @media screen and (max-width: $breakpoint-medium) {
      position: static;
      transform: translateY(0);
    }

    .img {
      text-align: center;

      svg {
        width: 203px;
        height: 308px;
        // width: calc(90% - 60px);
        // height: calc(100vh - 60px);
        // max-height: 640px;
        // margin: 30px;

        @media screen and (max-width: $breakpoint-medium) {
          width: 100%;
          height: auto;
          margin-top: 0px;
        }

        @media screen and (max-width: $breakpoint-small) {
          margin-top: -40px;
          padding-top: 60px;
        }
      }
    }

    .diversify_text {
      max-width: 540px;
      margin: auto;
      text-align: center;
      min-height: 90px;

      h4 {
        color: $color-white;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
      }

      p {
        margin-top: 10px;
        color: $color-white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      @media screen and (max-width: $breakpoint-medium) {
        display: none;
      }
    }

    // .diversify_text {
    //   max-width: 380px;
    //   margin: auto;
    //   text-align: center;

    //   h4 {
    //     font-size: 28px;
    //     font-style: normal;
    //     font-weight: 700;
    //     line-height: normal;
    //     color: $color-white;
    //   }

    //   p {
    //     margin-top: 10px;
    //     color: $white-color;
    //     text-align: center;
    //     font-size: 15px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 140%;
    //   }

    //   @media screen and (max-width: $breakpoint-medium) {
    //     display: none;
    //   }
    // }
  }
}

// Verify Email address UI css

.verify_code {
  margin: 32px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  input {
    width: 46px;
    height: 56px;
    background: $light-gray;
    border: 1px solid$input-border;
    border-radius: 12px;
    text-align: center;
  }
}

.resendCode {
  margin-top: 43px;
  text-align: center;

  @media screen and (max-width: $breakpoint-medium) {
    margin-top: 26px;
  }

  a {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;
    color: $color-yellow;
  }
}

@media screen and(max-width:$breakpoint-large) {
  .signup_page .sign_form {
    padding: 25px;
  }

  .verify_code input {
    width: 35px;
    height: 45px;
  }
}

@media screen and(max-width:1199px) {
  .signup_page .sign_form {
    padding: 45px;
  }
}

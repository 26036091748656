@import "../../styles/variables.scss";

.allocationRow {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $table-row-bg;
  border-radius: 12px;
  margin-bottom: 12px;
  padding-left: 15px;

  .allocationRowCell {
    width: 20%;
    text-align: left;
    padding: 16px 0px;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: var(--text-dark);
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--peragraph-text);
    }
  }

  .changeUsd {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
}

.change {
  display: flex;
  align-items: center;
  gap: 7.6px;

  .blue {
    color: var(--color-blue) !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  gap: 6px;
}

@import "../../../styles/variables";
@import "../../../styles/mixins";
.InvestmentInfoPlan {
  .stakeYour {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #eee9f6;
    border-radius: 10px;
    // padding: 40px 30px 24px;
    margin-bottom: 32px;
    @media screen and (max-width: $breakpoint-allmobile) {
      // padding: 8px 24px 24px;
    }
    .stakeYourText {
      width: 65%;
      padding: 40px 0 24px 30px;
      @media screen and (max-width: $breakpoint-allmobile) {
        padding: 8px 24px 24px 24px;
      }
      .bannerText {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: $peragraph-text;
        margin-top: 24px;
        @media screen and (max-width: $breakpoint-allmobile) {
          margin-top: 0;
        }
      }
      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
      }
    }
    .stakeYourImg {
      width: 35%;
      text-align: center;
      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        margin-top: 24px;
      }

      img {
        width: 100%;
      }
    }
  }
  .InfoNeal {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $white-color;
    border-radius: 10px;
    padding: 40px 24px;
    margin-bottom: 35px;
    @media screen and (max-width: $breakpoint-allmobile) {
      margin-bottom: 32px;
      padding: 20px 16px;
    }
    .autocalcutaltorTitle {
      width: 100%;
    }
    .innerLeft {
      width: 48%;

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
      }

      .inputClass {
        position: relative;
        .usd {
          position: absolute;
          bottom: 10px;
          right: 16px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 140%;
          color: $title-color;
        }
      }

      .ProgressBar {
        height: 4px;
        background: #f6f6f7;
        border-radius: 10px;
        margin: 24px 0;
        position: relative;

        &::after {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          left: 0;
          top: -5px;
          background-color: $title-color;
          content: "";
          position: absolute;
        }
      }

      .cryptoAmount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .textRight {
          display: block;
          text-align: right;
        }

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $input-label;
          margin-bottom: 12px;
        }
      }

      .mainTerms {
        margin-top: 32px;
        @media screen and (max-width: $breakpoint-allmobile) {
          margin-top: 25px;
        }
        span {
          margin-bottom: 16px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $input-label;
          display: inline-block;
          margin-bottom: 10px;
        }

        .terms {
          button {
            padding: 11px 24px;
            background: $white-color;
            border: 1px solid $input-border;
            border-radius: 10px;
            margin-right: 10px;
            font-weight: 500;
            color: $peragraph-text;
            @media screen and (max-width: 1023px) {
              margin-top: 10px;
            }
            @media screen and (max-width: $breakpoint-allmobile) {
              margin-right: 2px;
              width: 75px;
            }
          }
        }
      }
      .Investmentline {
        margin-top: 31px;
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $peragraph-text;
          margin-bottom: 12px;
        }
        h5 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: $title-color;
        }
      }
    }

    .innerRight {
      width: 48%;

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        margin-top: 24px;
      }

      .cryptoAmount {
        margin-top: 24px;
      }

      .cryptoAmount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          width: 50%;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $input-label;
            margin-bottom: 12px;
          }
        }
      }

      .rewards {
        background: $table-row-bg;
        border-radius: 12px;
        padding: 24px 24px 32px;
        margin-bottom: 20px;
        @media screen and (max-width: $breakpoint-allmobile) {
          padding: 16px;
        }

        .blue {
          font-weight: 700;
          font-size: 28px;
          color: $title-color;
          @media screen and (max-width: $breakpoint-allmobile) {
            font-size: 22px;
          }
        }

        ul {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-bottom: 24px;
          border-bottom: 1px solid $input-border;
          @media screen and (max-width: $breakpoint-allmobile) {
            padding-bottom: 16px;
          }
          li {
            width: 50%;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: $input-label;
              margin-bottom: 12px;
            }
          }
        }
      }
      .learnMore {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $btn-text-color;
        max-width: 108px;
        height: 32px;
        margin-left: auto;
      }

      p {
        svg {
          width: 100%;
        }
      }
    }

    strong {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      @media screen and (max-width: $breakpoint-allmobile) {
        font-size: 16px;
      }
      span {
        color: $input-label;
        font-size: 12px;
        font-weight: 500;
        @media screen and (max-width: $breakpoint-allmobile) {
          display: block;
        }
      }
    }
  }
  .whyInvestingContent {
    @include flexProperty;
    align-items: flex-start;
    .contentLeft {
      width: calc(50% - 20px);
      margin-right: 20px;
      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
        margin-right: 0;
      }
      .activeContent {
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        color: $main-color-70;
        padding-left: 8px;
        // padding-left: 17px;
        @media screen and (max-width: $breakpoint-medium) {
          margin-bottom: 32px;
          margin-top: 20px;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 5px;
          background-color: $color-yellow;
          border-radius: 8px;
        }
      }
    }
    .contentRight {
      width: calc(50% - 20px);
      margin-left: 20px;
      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
        margin-left: 0;
      }
    }
    .content {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: $peragraph-text;
      margin-bottom: 35px;
      @media screen and (max-width: $breakpoint-medium) {
        margin-bottom: 12px;
      }
    }
    .installNow {
      max-width: 244px;
      @media screen and (max-width: $breakpoint-small) {
        max-width: 100%;
      }
    }
  }
}
.installButton {
  height: 40px;
  margin-top: 24px;
}

@import "../../../styles/variables";
.flex {
    margin-top: 17px;
    gap: 20px;
    align-items: flex-start;

    @media screen and (max-width: $breakpoint-medium) {
        flex-direction: column;
    }
}

@import "../../styles/variables.scss";

.card {
  height: 330px;
}

.container {
  margin-top: 20px;
}

.btnContainer {
  width: 300px;
  margin-top: 24px;

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }
}

.modalWidth {
  max-width: 80% !important;
}

.bottom {
  width: 300px;

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $input-label;
    margin-bottom: 24px;
    margin-top: 24px;

    a {
      color: $color-yellow;
      font-weight: bold;
    }
  }
}

.kyc {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background-color: $title-color;
    color: $color-white;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
  }
}

.btn {
  height: 32px;

  @media screen and (max-width: $breakpoint-medium) {
    height: 40px;
  }
}

.flex {
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .info {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.vedioTitle {
  h2 {
    font-size: 28px;
    margin-bottom: 16px;

    @media screen and (max-width: $breakpoint-allmobile) {
      font-size: 20px;
    }
  }
}

.popupVedio {
  // max-width: 876px;
  width: 100%;
  margin-top: 32px;
  border-radius: 20px;
  border: 7.424px solid $purple-Bg-Color;
  background: #fff;
  box-shadow: 0px 0px 34.24065399169922px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;

  .vedioDotsSection {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    width: 100%;
    height: 30px;
    margin-bottom: -6px;

    .vedioDots {
      width: 10.588px;
      height: 10.588px;
      background-color: #000;
      border-radius: 50%;
      display: inline-block;
      margin-left: 10px;
    }
  }

  .vedio {
    position: relative;
    // min-height: 450px;
    bottom: -6px;

    @media screen and (max-width: $breakpoint-allmobile) {
      min-height: auto;
    }

    .watchVideo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 48px;
      width: 48px;
      border-radius: 50%;
      outline: none;
      background-color: $title-color;
      border: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      cursor: pointer;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.CloseIcon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.onboardingCard {
  p {
    @media screen and (min-width: 1300px) {
      width: 80%;
    }

    @media screen and (max-width: 1400px) {
      min-height: 85px;
    }

    @media screen and (max-width: $breakpoint-allmobile) {
      min-height: auto;
    }
  }
}

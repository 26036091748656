@import "../../styles/variables.scss";

.cryptoToken {
  background-color: $white-color;
  border-radius: 10px;
  margin-top: 12px;
  padding-top: 10px;

  .nftCrypto {
    border-bottom: 1px solid $input-border;
    border-radius: 0;
  }
}

.customAccordion {
  margin-bottom: 16px;
  border-radius: 10px !important;
  background-color: $white-color !important;
  box-shadow: none !important;

  &::before {
    background-color: transparent !important;
  }

  .innderIconAccord {
    position: relative;
    height: 35px;
    width: 35px;
    border: 2px solid #e8e1f6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global {
    .css-yw020d-MuiAccordionSummary-expandIconWrapper {
      @media screen and (max-width: $breakpoint-allmobile) {
        position: absolute;
        top: 17px;
        right: 16px;
      }
    }
  }
}

.customAccordionChild {
  background-color: $table-row-bg !important;
  margin-bottom: 16px;
  padding: 10px;
  box-shadow: none !important;
  border-radius: 12px !important;

  &::before {
    background-color: transparent !important;
  }

  :global {
    .MuiAccordionSummary-expandIconWrapper {
      @media screen and (max-width: $breakpoint-large) {
        top: 11px !important;
        position: absolute;
        right: 0 !important;
      }

      @media screen and (max-width: $breakpoint-small) {
        bottom: 10px;
        top: inherit !important;
        position: absolute;
        right: 0;
      }
    }

    .Mui-expanded {
      @media screen and (max-width: $breakpoint-small) {
        bottom: 16px !important;
      }
    }
  }
}

.tableRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-large) {
    flex-wrap: wrap;

    .td {
      width: 50%;
      margin-bottom: 16px;
    }
  }
}

.innerContent {
  border-top: 1px solid $input-border;

  @media screen and (max-width: $breakpoint-small) {
    padding: 0 !important;
  }
}

.StakingCard {
  column-gap: 16px;
  justify-content: space-evenly;
  @media screen and (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
  }

  .tokenCard {
    @media screen and (max-width: $breakpoint-small) {
      width: 31%;
      padding: 8px;
    }
  }

  .availableBalance {
    background-color: $white-color;
    border-radius: 10px;
    justify-content: space-between;
    padding-right: 12px;

    @media screen and (min-width: $breakpoint-small) {
      display: none;
    }

    .addButton {
      max-width: 72px;
      width: 100%;
      height: 32px;
    }
  }
}

.content {
  width: 100%;
  // border-top: 1px solid $input-border;
}

hr {
  border: none;
  height: 1px;
  background-color: $input-border;
}

th {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #9599b5;
}

.td {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: $title-color;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #525885;

    @media screen and (max-width: $breakpoint-allmobile) {
      display: block;
      margin-bottom: 12px;
    }
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: $breakpoint-allmobile) {
    width: 100%;
    gap: 8px;
  }

  .btn {
    border: 1px solid $input-border;
    border-radius: 20px;
    padding: 5px 15px;
    color: $title-color;
    background-color: $white-color;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #525885;

    @media screen and (max-width: $breakpoint-small) {
      width: 100px;
      padding: 5px 10px;
      font-size: 13px;
    }
  }
}

.active {
  background-color: $main-color-50 !important;
  color: $white-color !important;
  border: none !important;
}

.innerTableTitle {
  h2 {
    @media screen and (max-width: $breakpoint-small) {
      margin-top: 16px;
    }
  }
}
.stakingTable {
  :global {
    .SoHcp {
      background-color: $table-row-bg;
    }
    .rdt_TableRow {
      border-bottom: 0 !important;
      outline: none;
      border-radius: 10px;
    }
    .rdt_Table {
      background-color: $table-row-bg;
    }
    .rdt_TableCell {
      color: #525885;
    }
    .jBKeyo:hover {
      background-color: #fff;
      border-color: #fff;
    }
  }
}

@import "../../styles/variables";

.overViewCard {
  text-align: left;
  min-width: 180px;
  min-height: 180px;

  h3 {
    margin: 16px 0;
    font-size: 16px;
    color: $main-color !important;
  }

  div {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $main-color-70;

    a {
      font-weight: 500;
      color: $color-yellow;
    }
  }
}

@import '../../colorVariables.scss';

.accordion {
    .accordionIttem {
        background-color: $white-color;
        padding: 25px 32px;
        list-style: none;
        margin-bottom: 16px;
        margin-top: 16px;
        @media screen and (max-width:767px) {
            padding: 20px 16px; 
            margin-bottom: 8px;
            margin-top: 0;
        }

        h2 {
            margin: 0;

            .accordionBtn {
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 140%;
                color: $title-color;
                background-color: transparent;
                border: 0;
                outline: none;
                width: 100%;
                text-align: left;
                cursor: pointer;

                svg {
                    float: right;
                }
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: $label-color;
            text-align: left;
            padding-top: 16px;
        }

    }
}
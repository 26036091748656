@import "../../../styles/variables";
@import "../../../styles/mixins";
.autoPlanDetails {
  .Rewardsdiagram {
    margin-top: 24px;
    svg {
      width: 100%;
    }
  }
  .customTimeline {
    position: relative;
    margin-bottom: 32px;
    .month {
      position: absolute;
      right: 14px;
      bottom: 11px;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: $title-color;
    }
  }
  .earningInquery {
    background-color: $table-row-bg;
    padding: 24px 20px;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $peragraph-text;
    }
    .earningRewards {
      @include flexProperty;
      .Rewards {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        letter-spacing: 0.02em;
        color: $title-color;
      }
    }
    .earningItems {
      @include flexProperty;
      margin-top: 15px;
      strong {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: $title-color;
      }
    }
  }
  .flexButton {
    margin-bottom: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0;
    .investButton {
      button {
        @media screen and (max-width: $breakpoint-medium) {
          padding: 11px 20px 11px 20px;
        }
      }
    }
  }
  .timeline {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $peragraph-text;
    margin-top: 16px;
  }
  .InvestingPlanTitle {
    h2 {
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 24px;
    }
  }
  .MinimumText {
    position: relative;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $peragraph-text;
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
  .tokenHeading {
    font-size: 15px;
    font-weight: 700;
    line-height: 140%;
    color: $title-color;
  }
}
.flexItems {
  margin: 16px -12px;
  flex-wrap: wrap;
  @media screen and (max-width: $breakpoint-small) {
    margin: 16px 0;
  }
  .selsectBox {
    width: 50%;
    padding: 0 12px;
    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
      padding: 0;
    }
  }
}
.addToken {
  font-size: 16px;
  font-weight: 700;
  color: #9ca3af;
  max-width: 150px;
  width: 100%;
  text-align: center;
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  .addAnotherToken {
    color: $color-yellow;
  }
}

@import "../../styles/variables.scss";
.complete_Registration {
  padding: 40px 24px !important;
}
.container {
  margin-top: 24px;
}

.unFade {
  opacity: 1 !important;
  pointer-events: all !important;
}

.cardInner {
  .cardTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .cardTopLeft {
      text-align: left;
      width: 80%;

      @media (max-width: $breakpoint-large) {
        width: 100%;
        margin-bottom: 10px;
      }

      h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: $title-color;
        margin-bottom: 24px;
        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 12px;
        }
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: $input-label;
        margin-top: 2px;
        margin-bottom: 20px;
        width: 60%;
        @media screen and (max-width: $breakpoint-large) {
          width: 100%;
          margin-top: 12px;
        }
      }

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: $input-label;
        margin-bottom: 0px;
      }
    }

    .btn {
      width: 240px;
      height: 40px;
      @media screen and (max-width: $breakpoint-large) {
        margin-bottom: 16px;
        margin-top: 16px;
        margin-right: auto;
      }
      @media (max-width: $breakpoint-medium) {
        display: none !important;
      }
    }

    @media (max-width: $breakpoint-large) {
      flex-direction: column;
    }
  }

  .steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    @media screen and (min-width: $breakpoint-double-extra-large) {
      column-gap: 30px;
    }
    @media (max-width: 1023px) {
      gap: 24px;
    }
    .rightArrowIcon {
      @media (max-width: 1200px) {
        width: 20px;
        height: 20px;
      }
    }

    @media (max-width: 1023px) {
      .rightArrowIcon {
        display: none !important;
      }
    }
    .setIcon {
      position: relative;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 200px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      &:last-child {
        flex-basis: calc(200px - 62px);
        .rightArrowIcon {
          display: none;
        }
      }
      @media screen and (min-width: $breakpoint-double-extra-large) {
        column-gap: 30px;
      }
      @media screen and (max-width: $breakpoint-small) {
        flex-basis: 100%;
      }
      .InnerContainer {
        min-width: inherit;
        width: 100%;
      }
    }
  }

  .btn1 {
    display: none !important;

    @media (max-width: $breakpoint-medium) {
      display: inline-block !important;
      margin-top: 24px;
    }
  }
}

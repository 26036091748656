@import "../../styles/variables";

.loyaltyStepper {
  margin-top: 24px;
  background-color: $main-color-20;
  padding: 32px;
  border-radius: 20px;

  @media screen and (max-width: $breakpoint-allmobile) {
    padding: 24px;
  }

  .innerStepper {
    @media screen and (max-width: $breakpoint-allmobile) {
      flex-wrap: wrap;
    }
  }

  .showInputstepperValue {
    margin-top: 16px;
    margin-bottom: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    order: 1;
    width: 20%;
    border-right: 1px solid $input-border;
    font-size: 28px;
    color: $title-color;
    font-weight: 700;

    @media screen and (max-width: $breakpoint-allmobile) {
      width: 100%;
      border-right: 0;
      border-bottom: 1px solid $input-border;
    }
  }

  .stepper {
    margin-top: 40px;
    margin-bottom: 50px;
    order: 2;
    width: 80%;

    @media screen and (max-width: $breakpoint-allmobile) {
      width: 100%;
      flex-direction: column;
      height: 400px;
      margin-bottom: 0px;
    }

    .stepperLabel {
      white-space: nowrap;
      background-color: red;
    }
  }

  .avtiveIcon {
    fill: #835bbe !important;
    border: 2px solid #835bbe !important;
  }

  .iconText {
    fill: $color-white;
    opacity: 0;
    color: red;
  }

  .completed {
    fill: $title-color;
    border: 2px solid #835bbe !important;
  }

  .root {
    fill: #d4c7f0;
    border: 2px solid #d4c7f0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 4px;
  }

  .commonStep {
    @media screen and (max-width: $breakpoint-allmobile) {
      display: flex;
      align-items: center;
      flex-direction: row !important;
    }

    span:last-child {
      margin-top: -36px;
      max-width: 74px;
      color: $title-color;

      @media screen and (max-width: $breakpoint-allmobile) {
        text-align: left;
        margin: 0;
        padding-left: 7px;
      }
    }
  }

  .levelTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
    color: $title-color;
  }

  .labelpoints {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
    color: $title-color;
    display: inline-block;
    padding-left: 12px;

    @media screen and (max-width: $breakpoint-allmobile) {
      float: right;
    }
  }

  .outerCompleted {
    width: 25px;
    height: 25px;
    background-color: $white-color;
    border-radius: 50%;
    border: 2px solid $stepper-dark;
    display: flex;
    align-items: center;
    justify-content: center;

    .innerCompleted {
      width: 15px;
      height: 15px;
      background-color: $stepper-dark;
      border-radius: 50%;
    }
  }

  .outerNotCompleted {
    width: 25px;
    height: 25px;
    background-color: $stepper-light;
    border-radius: 50%;
    border: 2px solid $stepper-light;
    display: flex;
    align-items: center;
    justify-content: center;

    .innerNotCompleted {
      width: 15px;
      height: 15px;
      background-color: $stepper-light;
      border-radius: 50%;
    }
  }
}

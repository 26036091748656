@import "../../styles/variables.scss";

.dashboardTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: $title-color;
  margin-bottom: 24px;
  margin-top: 32px;

  @media screen and (max-width: $breakpoint-medium) {
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
.content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $peragraph-text;
}

@import "../../styles/variables.scss";

.blogCard {
  width: 100%;
  background-color: $white-color;
  border-radius: 10px;
  overflow: hidden;

  .blogCardWrapper {
    padding: 20px 16px;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      margin-bottom: 32px;
      cursor: pointer;
    }
  }

  .blogFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    P {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $title-color;
    }

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $input-label;
    }
  }

  .blogCardImg {
    max-height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      cursor: pointer;
    }
  }
}

.modalWrapper {
  .modalContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: $title-color;
      text-transform: capitalize;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: $title-color;
      text-transform: capitalize;
    }

    .modalTitle {
      h2 {
        margin-top: 0;
      }
    }
  }

  .para {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0px;
    word-break: break-all !important;
  }

  .imgContainer {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 12px;
    background-color: $purple-light;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.desc {
  margin-top: 30px;
}

.author {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: $title-color;
  text-transform: capitalize;
  margin-top: 10px;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 32px;

  .btn {
    width: 40%;
  }
}

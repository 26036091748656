@import "../../styles/variables";
.ratingcard {
  background-color: $white-color;
  border-radius: 12px;
  max-width: 543px;
  width: 100%;
  padding: 12px 24px 12px 12px;
  display: flex;
  align-items: center;

  .iconText {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-wrap: wrap;
    }
    .text {
      display: flex;
      align-items: center;
      padding-left: 12px;

      p {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $title-color;
      }
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: $color-yellow;
        padding-left: 18px;
        @media screen and (max-width: 1199px) {
          display: none;
        }
      }
    }
  }
  .points {
    @media screen and (max-width: 1199px) {
      width: 100%;
      padding-left: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
    }
    a {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: $title-color;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: $color-yellow;
      @media screen and (min-width: 1100px) {
        display: none;
      }
      @media screen and (max-width: 1199px) {
        display: block;
        order: 2;
      }
    }
  }
}

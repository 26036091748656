@import "../../../styles/variables";

.SelectItem {
  border-radius: 12px;
  border: 1px solid $input-border;
  background: $light-gray;
  padding: 12px 12px 6px 12px;
  margin: 4px 0px;

  .SelectItemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: $title-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    p.balance {
      font-weight: 700;
    }
  }

  p {
    color: $title-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14.5px;

    h4 {
      color: $title-color;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.select {
  width: 100%;
  border: none;
  outline: none;
  color: $title-color;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &::placeholder {
    // color: red
  }
}

option {
  color: $title-color;
  padding: 10px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  // margin-right: 2rem;
  padding: 8px;
  padding-right: 2rem;
}

.reactSelect {
  margin-bottom: 0px !important;
}

@import "../../styles/variables.scss";

.mainHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0;
  position: fixed;
  min-height: 96px;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 7;
  background-color: #f6f6f7;

  @media screen and (max-width: $breakpoint-medium) {
    padding: 14px 0;
  }

  .headerInner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-right: 40px;
    // max-width: 700px;

    @media screen and (max-width: $breakpoint-large) {
      max-width: 50%;
      padding-right: 16px;
    }

    @media screen and (max-width: $breakpoint-medium) {
      max-width: 700px;
      padding-right: 0px;
    }

    .mobileIcon {
      display: flex;
      align-items: center;
      max-width: 100px;
      justify-content: space-between;
      width: 100%;

      svg {
        height: 40px;
        margin-left: 12px;
        fill: $main-color;
        &:first-child {
          @media screen and (max-width: $breakpoint-small) {
            margin-left: 0;
          }
        }
      }
      @media screen and (min-width: $breakpoint-xsmall) {
        display: block;
      }
      @media screen and (min-width: $breakpoint-medium) {
        display: none;
      }
    }
  }
}

// .fade {
//   opacity: 0.3;
//   pointer-events: none;
// }

@media (max-width: $breakpoint-medium) {
  .mainHeader {
    padding: 15px;

    .hamburger {
      display: block;
    }

    .headerInner {
      width: 100%;
      justify-content: space-between;

      .logoContainer {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .wallet {
        width: 50%;
        height: 43px;
        margin: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 5px;
        border: 1px solid $title-color;
        padding: 5px;
        border-radius: 30px;
      }
    }
  }
}

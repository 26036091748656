@import "../../styles/variables.scss";

.identity {
  padding: 0px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--text-dark);
    margin-bottom: 32px;
  }

  .boxWrapper {
    border: 1px solid #e8e1f6;
    border-radius: 10px;
    padding: 32px;
    margin-bottom: 20px;
  }

  .topBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .boxLeft {
    width: 50%;

    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: var(--input-label);
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #4e307a;
      margin: 20px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: var(--input-label);
    }
  }

  .boxRightSuccess {
    .successBtn {
      width: 170px !important;
      height: 32px !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 19px !important;
      background: #efeeff;
      border: none;
      color: var(--color-blue);
    }
  }

  .boxRight {
    // width: 50%;
    p {
      text-align: center;
      margin: 8px 0px;
    }

    .btn {
      width: 170px;
      height: 32px;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }
  }

  hr {
    margin: 24px 0px;
    border: 1px solid var(--input-border);
  }

  .bottomBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottomLeft {
    width: 50%;

    p,
    li {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: var(--input-label);
      margin-bottom: 10px;
    }

    li {
      &::before {
        content: "\2022";
        color: var(--color-blue);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: 0em;
      }
    }
  }

  .bottomRight {
    display: flex;
    align-items: center;
    gap: 12px;

    .passport {
      width: 125px;
      height: 88px;
      background: var(--table-row-bg);
      border: 0.5px solid var(--input-border);
      border-radius: 12px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .passIcon1 {
        margin-right: 15px;
      }

      .passIcon2 {
        position: absolute;
        top: -26px;
        left: 10px;
      }

      .passIcon3 {
        width: 26px;
        position: absolute;
        top: 0px;
        left: 10px;
      }

      .icon {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }

  .agree {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 0px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: var(--input-label);
      // margin-bottom: 5px;
    }
  }

  .skipText {
    display: none;
  }

  .skip {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      color: $link-color;
      cursor: pointer;
    }
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.btnParent {
  width: 242px !important;
}

.approveModal {
  max-width: 500px;
  text-align: center;

  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  .continue {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .width220 {
    width: 220px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    color: $title-color;
    margin-top: 42px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $input-label;
    margin-top: 16px;
    margin-bottom: 48px;
  }

  .btnContainer2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}

.modal {
  // min-width: 400px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: var(--input-label);
    margin-top: 20px;
  }

  .modalTitle {
    // margin-bottom: 10px !important;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: $title-color;
    margin-bottom: 32px;
  }

  .radioBtn {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 32px;

    .radioWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        align-items: center;
        color: $input-label;
      }
    }
  }

  .continue {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: $breakpoint-small) {
  .topBox {
    flex-direction: column;

    .boxLeft {
      width: 100%;
    }
  }

  .btnParent {
    width: 100% !important;
  }

  .bottomRight {
    .passport {
      width: 90px !important;
    }

    // justify-content: center;
    // flex-wrap: wrap;
  }

  .btnContainer {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 20px;
  }

  .boxRightSuccess {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }

  .boxRight {
    width: 100%;
    margin-top: 24px;

    p {
      margin: 8px 0px;
    }

    .btn {
      width: 100% !important;
    }
  }

  .modal {
    .radioBtn {
      flex-direction: column;
      align-items: flex-start;

      .radioWrapper {
        margin-bottom: 0px;
      }
    }
  }

  .btnContainer2 {
    flex-direction: column-reverse;
  }

  .skip {
    h5 {
      // display: none !important;
    }
  }

  .skipText {
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: $link-color;
    cursor: pointer;
    padding-bottom: 50px;
    margin-top: 34px;
  }
}

@media (max-width: $breakpoint-medium) {
  .bottomBox {
    flex-direction: column;

    .bottomLeft {
      width: 100%;
    }
  }
}

@import "../../styles/variables.scss";

.howitwork {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .content {
    padding-left: 24px;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      margin-bottom: 12px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: $main-color-70;
      max-width: 208px;

      @media screen and (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  .icon {
    svg {
      fill: $main-color;
      @media screen and (max-width: 767px) {
        height: 56px;
        width: 40px;
      }
    }
  }
}

@import "../../styles/variables";
@import "../../styles/mixins";

.EditCancelPopup {
  .basicTitle {
    text-align: center;

    h2 {
      margin-top: 0px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: $title-color;
    margin-bottom: 16px;

    @media screen and (max-width: $breakpoint-medium) {
      margin-bottom: 8px;
      font-size: 20px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $peragraph-text;
    margin-bottom: 32px;

    @media screen and (max-width: $breakpoint-medium) {
      margin-bottom: 24px;
      font-size: 14px;
    }
  }

  .buttonGroup {
    @include flexProperty;

    .cancel {
      width: 48%;

      @media screen and(max-width:$breakpoint-small) {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    .update {
      width: 48%;

      @media screen and(max-width:$breakpoint-small) {
        width: 100%;
      }
    }
  }
}

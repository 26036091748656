@import "../../../styles/variables";

.amountContainer {
  background: $light-gray;
  border: 1px solid $input-border;
  border-radius: 12px;
  margin-bottom: 12px;
  padding: 12px;

  .amountTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $title-color;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $input-label;
    }
  }

  .amountBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 29px;
      color: $input-placeholder;
    }

    .amountBottomRight {
      display: flex;
      align-items: center;
      max-width: 96px;

      .coin {
        width: 100%;
        height: 32px;
        background: $white-color;
        border: 1px solid $purple-light;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-left: 12px;
        padding: 5px 10px;

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          align-items: center;
          letter-spacing: 0.02em;
          color: $btn-text-color;
        }
      }

      .max {
        background: $white-color;
        border-radius: 24px;
        width: 49px;
        height: 24px;

        span {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.02em;
          color: $color-yellow;
        }
      }
    }
  }
}

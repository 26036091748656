@import "../../../styles/variables";

.detail {
  flex: 1;
  padding: 16px;
  background-color: $white-color;
  border-radius: 10px;

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }

  h3 {
    color: $title-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 2px;
  }

  .detailInfo {
    flex: 1;

    p {
      color: $tableheader;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    h4 {
      color: $title-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        // position: absolute;
        // top: 0px;
        // right: 48px;
        display: flex;
        align-items: center;
        gap: 5px;
        color: $stepper-dark;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .error {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $text-danger;
    margin-top: -12px;
  }

  .mar25 {
    margin-top: 25px;
  }

  .flex {
    margin-top: 20px;
  }

  .withdrawalBTn {
    margin-top: 30px;

    @media screen and (max-width: $breakpoint-medium) {
      margin-top: 24px;
    }
  }
}

.inputClass {
  padding-left: 15px;
  background-color: $light-gray;
}

@import "../../styles/variables";

.helpCenter {
  .faqTitle {
    h2 {
      margin-top: 40px;
      @media screen and (max-width: $breakpoint-medium) {
        margin-top: 24px;
        margin-bottom: 16px;
      }
    }
  }
  .helpCenterBox {
    max-width: 100%;
    min-height: 200px;
    @media screen and (max-width: $breakpoint-small) {
      min-height: auto;
    }
    a {
      display: block;
      margin-top: 12px;
      color: $peragraph-text;
      font-weight: 500;
      font-size: 15px;
      line-height: 140%;
    }
    h3 {
      color: $title-color;
      font-size: 18px;
    }
  }
  .removeGap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    column-gap: 24px;
    row-gap: 24px;
    @media screen and (max-width: $breakpoint-medium) {
      row-gap: 16px;
      padding: 0;
      column-gap: 16px;
    }
  }
}

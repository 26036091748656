@import "../../styles/variables.scss";

.container {
  min-width: 225px;
  flex: 1;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    background-color: $main-color;
    overflow: hidden;
    border-radius: 12px 12px 0px 0px;

    .topRight {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-white;
    }
  }

  .bottom {
    background-color: $table-row-bg;
    border-radius: 0px 0px 12px 12px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: #525885;
    }

    @media (max-width: $breakpoint-medium) {
      flex-direction: row;
      align-items: center;
    }
  }
}
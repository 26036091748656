@import "../../styles/variables.scss";

.back {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 50px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: $color-yellow;
  }
}

.stakContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .stakMainDiv {
    width: 50%;
    background-color: $color-white;
    border-radius: 8px;
  }

  .cardWrapper {
    padding: 60px;
    margin: auto;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: $title-color;
      margin-bottom: 28px;
    }

    .buttons {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 3px;
      border-radius: 50px;
      border: 1px solid $input-border;
      overflow: hidden;
      background-color: #f6f6f7;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: $peragraph-text;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        align-items: center;
        color: $peragraph-text;
      }
    }

    .titleInput {
      background: $light-gray;
      border: 1px solid $input-border;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;
      margin-top: 8px;
    }

    .max {
      border-left: 1px solid $input-border;
      padding-left: 10px;

      h4 {
        background-color: $white-color;
        border-radius: 30px;
        padding: 2px 10px;

        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $color-yellow;
      }
    }

    input {
      outline: none;
      border: none;
      background-color: $light-gray !important;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      padding-left: 0px;
    }

    .titleInputMobContainer {
      display: none;

      .titleInputMob {
        background: $light-gray;
        border: 1px solid $input-border;
        border-radius: 12px;

        .titleInputMobTop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px;

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: $title-color;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: $peragraph-text;
          }
        }

        .inputContainerMob {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 10px;
        }
      }
    }

    .claim {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      margin-bottom: 16px;
      margin-top: 24px;
    }

    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-top: 39px;
    }

    .tokenDetail {
      background-color: $table-row-bg;
      padding: 24px 20px;
      border-radius: 12px;
      margin-top: 32px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: $peragraph-text;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $title-color;
      }

      .colorChange {
        color: $title-color !important;
      }
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .back {
    margin-bottom: 22.5px;
  }

  .stakContainer {
    .stakMainDiv {
      width: 100%;
    }

    .cardWrapper {
      padding: 16px;
    }

    .btnContainer {
      flex-direction: column-reverse;
    }

    .titleInput {
      display: none !important;
    }

    .titleInputMobContainer {
      display: block !important;
    }

    .title {
      display: none !important;
    }
  }
}

@media (min-width: $breakpoint-medium) and (max-width: 1166px) {
  .stakMainDiv {
    width: 100% !important;
  }
}
.comfirmContent {
  text-align: center;
  button {
    max-width: 220px;
    margin: auto;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 100%;
    }
  }
}

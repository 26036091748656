@import "../../styles/variables.scss";

.flexGap {
  gap: 10px;
}

.sort_btn {
  width: 40px;
  height: 25px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $main-color-70;
  background-color: $white-color;
}

.bgColor {
  background-color: $dark-sortByYear !important;
  color: $white-color;
}

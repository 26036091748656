@import "../../styles/variables.scss";

// $breakpoint-medium
.SellNealthyToken {
  max-width: 600px;
  width: 100%;
  background: $color-white;
  box-shadow: 0px 15px 50px -12px rgba(109, 112, 116, 0.16);
  border-radius: 10px;
  padding: 48px 60px;
  margin: auto;

  @media screen and(max-width:$breakpoint-medium) {
    padding: 16px;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 34px;
  }

  .nStableContainer {
    display: flex;
    align-items: center;
    background-color: $main-color;
    color: $white-color;
    padding: 2px 10px;
    border-radius: 8px;

    span {
      color: $white-color;
      font-size: 14px;
      font-weight: 400;
      padding-right: 5px;
    }
  }

  .SellNealthyCard {
    padding: 12px;
    background: $light-gray;
    border: 1px solid $input-border;
    border-radius: 12px;
    margin-bottom: 12px;
    position: relative;

    .douwnArrow {
      position: absolute;
      left: 45%;
      transform: translateY(-50%);
      top: -10px;
    }

    span {
      color: $peragraph-text;
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      float: right;
    }

    button {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $color-yellow;
      background-color: $white-color;
      border-radius: 24px;
      padding: 0 12px;
      height: 24px;
      border: 0;
      outline: 0;
      margin-right: 12px;
      cursor: pointer;
    }

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $title-color;
      display: inline-block;
    }

    .SellNealthycrytoValue {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;

      .inputContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        flex: 1;

        span {
          color: #525885;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 12px;
        }
      }

      .input {
        color: $title-color;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .SellNealthyoptons {
        position: relative;
        display: flex;
        align-items: center;

        select {
          width: 120px;
          height: 32px;
          background: $white-color !important;
          border: 1px solid #e8e1f6;
          border-radius: 24px;
          font-weight: 700;
          font-size: 14px;
          color: #4e307a;
          padding: 0 19px;
          outline: none;
          cursor: pointer;
        }

        svg {
          position: absolute;
          right: 19px;
          top: 13px;
        }
      }
    }
  }

  .SellNealthyBtn {
    margin-top: 40px;
  }

  .SellNealthyTitle {
    h2 {
      margin-bottom: 0px;
    }

    p {
      display: none;
      color: $title-color;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;

      @media screen and (max-width: $breakpoint-medium) {
        font-size: 16px;
      }
    }
  }

  .orderSummary {
    background: $table-row-bg;
    border-radius: 12px;
    padding: 24px 20px;

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      margin-bottom: 20px;
    }

    .orderList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      p {
        width: 50%;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: $light-black;
        margin-bottom: 15px;
      }

      strong {
        width: 50%;
        text-align: right;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        color: $title-color;
      }
    }
  }
}

.nStableBalance {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: $purple-Bg-Color;
  border: 1px solid $main-color;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;

  img {
    width: 25px;
  }
}

.error {
  margin-left: 11px !important;
}

@import "../../colorVariables.scss";

.StakCard {
  background: $white-color;
  border-radius: 10px;
  display: flex;
  align-items: center;
  max-width: 359px;
  width: 100%;
  padding: 16px 24px;
  margin-bottom: 32px;
  @media screen and (max-width: 767px) {
    padding: 12px 16px;
    max-width: 100%;
    margin-bottom: 0;
  }
  .texts {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $title-color;
      margin-bottom: 6px;
    }
    strong {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.02em;
      color: $title-color;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
}

@import "../../styles/mixins";
@import "../../styles/variables";
.cardsection {
  @include flexProperty;
  align-items: flex-start;
  margin-bottom: 32px;
  .leftIcon {
    width: 5%;
    @media screen and (max-width: $breakpoint-small) {
      display: none;
    }
  }
  .contentArea {
    width: calc(95% - 32px);
    margin-left: 32px;
    @media screen and (max-width: $breakpoint-large) {
      width: calc(95% - 50px);
      margin-left: 50px;
    }
    @media screen and (max-width: $breakpoint-small) {
      width: 100%;
      margin-left: 0;
    }
    .cardtitle {
      font-size: 20px;
      color: $title-color;
      font-weight: 700;
      margin-bottom: 12px;
      @media screen and (max-width: $breakpoint-small) {
        font-size: 15px;
      }
    }
    .cardDescription {
      font-size: 15px;
      line-height: 140%;
      color: $peragraph-text;
    }
  }
}

@import "../../styles/variables.scss";

.container {
  width: 100%;

  @media screen and (max-width: $breakpoint-small) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .welcome {
    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      color: $title-color;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      color: $input-label;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      color: $input-label;
      margin: 10px 0px 15px 0px;
    }

    .scannerImg {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: contain;

      img {
        width: 50%;
      }
    }
  }
}

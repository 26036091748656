@import "../../styles//variables.scss";
.summary {
  background: var(--white-color);
  border-radius: 10px;
  max-width: 604px;
  width: 100%;
  padding: 48px 60px;
  margin: auto;
  @media screen and (max-width: $breakpoint-medium) {
    padding: 24px 16px;
  }

  .eurBox {
    border: 1px solid #d4c7f0;
    border-radius: 12px;
    padding: 24px 70px;
    display: flex;
    position: relative;
    margin-bottom: 16px;
    justify-content: space-between;
    margin-top: 30px;
    @media screen and (max-width: $breakpoint-medium) {
      padding: 24px;
    }

    .arrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        transform: rotate(180deg);
      }
    }

    .eur {
      h5 {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #190044;
        @media screen and (max-width: $breakpoint-medium) {
          font-size: 15px;
        }

        svg {
          padding-right: 8px;
        }
      }
    }

    .eth {
      h5 {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #190044;
        @media screen and (max-width: $breakpoint-medium) {
          font-size: 15px;
        }

        svg {
          padding-right: 8px;
        }
      }
    }
  }

  .cryptoBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;

    .btnChild {
      width: 48%;
      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  .orderSummary {
    background: var(--table-row-bg);
    border-radius: 12px;
    padding: 24px 20px;

    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: var(--text-dark);
      margin-bottom: 20px;
    }

    .orderList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      p {
        width: 50%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: var(--input-label);
        margin-bottom: 15px;
      }

      strong {
        width: 50%;
        text-align: right;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: var(--text-dark);
      }
    }
  }

  .orderLink {
    margin-bottom: 40px;
    .term_link {
      color: $main-color;
    }
  }
}

@import "../../../styles/variables";
@import "../../../styles/mixins";
.customePlanPayment {
  .investingTitle {
    h2 {
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 30px;
    }
  }
  .creditDebitCard {
    @include flexProperty;
    .creditCardText {
      label {
        color: $title-color;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .paymentForm {
    width: 60%;
    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
    }
    .formCccNumber {
      @include flexProperty;
      margin: 0 -8px;
      @media screen and (max-width: $breakpoint-medium) {
        margin: 0;
      }
      .expirydate {
        padding: 0 8px;
        width: 50%;
        @media screen and (max-width: $breakpoint-medium) {
          width: 100%;
          padding: 0;
        }
      }
      .Cvv {
        padding: 0 8px;
        width: 50%;
        @media screen and (max-width: $breakpoint-medium) {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
  .multipleItems {
    @include flexProperty;
    border-bottom: 1px solid $input-border;
    padding: 16px 0;
  }
  .crypto {
    border-top: 1px solid $input-border;
    margin-top: 16px;
    padding: 26px 0;
  }
  .visacard {
    @media screen and (max-width: $breakpoint-medium) {
      margin-top: 18px;
      margin-bottom: 24px;
    }
  }
  .eachMonth {
    margin-top: 42px;
    @media screen and (max-width: $breakpoint-small) {
      margin-top: 23px;
    }
    .eachMonthCheckbox {
      label {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 140%;
        color: $peragraph-text;
      }
    }
  }
}

@import "../../styles/variables.scss";

.personalInfo {
  // max-width: 900px;
  padding: 20px;

  .flexBox {
    gap: 20px;

    @media screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
      gap: 0;
    }

    .personalInfo_Form_field {
      margin-bottom: 16px;
    }
  }
  .flexBoxhorizontal {
    gap: 20px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
      gap: 0;
    }

    .personalInfo_Form_field {
      margin-bottom: 16px;
    }
  }
}

.dnationality {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  .term_link {
    color: $title-color;
  }
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.infoIcon {
  width: 14px;
  height: 14px;
  margin: 2px 2px -2px 6px;
  cursor: pointer;
}

.dateContainer {
  width: 100%;
  margin-top: -2px;

  @media screen and (max-width: $breakpoint-small) {
    margin-bottom: 16px;
  }
}

.showEmail {
  display: block !important;
}

.hideEmail {
  display: none !important;
}

.flexSingle {
  width: 49%;
}

.walletBtn {
  width: 235px;
  height: 48px;
  border: 1px solid $title-color;
  border-radius: 20px;
  color: $title-color;
  padding: 6px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: $btn-text-color;
  cursor: pointer;
  margin-bottom: 80px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continueBtn {
  width: 242px;
}

.header {
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: $main-color;
  }
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $input-label;
}

h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: $main-color;
  margin-bottom: 10px !important;
  margin-top: 32px;
  margin-bottom: 16px;
}

.h5 {
  @media screen and (max-width: $breakpoint-small) {
    margin-top: 24px;
  }
}

.skipText {
  display: none;
}

.skip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media screen and (max-width: $breakpoint-small) {
    margin-bottom: 24px;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: $color-yellow;
    cursor: pointer;
    margin: 0 !important;
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 100px;
}

@media (max-width: $breakpoint-small) {
  .personalInfo {
    padding: 0 16px;

    // .header {
    //   margin-left: 10px;
    // }

    .walletBtn {
      width: 100%;
      margin-bottom: 20px;
    }

    .continueBtn {
      width: 100%;
    }

    .btnContainer {
      // padding: 0px 10px;
      margin-top: 8px;
    }

    .loc {
      margin-left: 10px;

      @media screen and (max-width: $breakpoint-small) {
        margin-top: 12px;
        margin-bottom: 16px !important;
        margin-left: 0;
      }
    }

    .flexSingle {
      width: 100%;
    }

    .skip {
      h5 {
        display: none;
      }
    }

    .skipText {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-decoration-line: underline;
      color: $color-yellow;
      cursor: pointer;
      padding-bottom: 50px;

      @media screen and (max-width: $breakpoint-small) {
        padding-bottom: 24px;
        margin-top: 24px;
      }
    }
  }
}

@import "../../../styles/variables";

.nftsTokenpage {
  margin-top: 32px;

  .graphSection {
    padding: 24px;
    background-color: $white-color;
    border-radius: 12px;
    margin-bottom: 24px;

    .graphImg {
      width: 100%;
      height: auto;
    }
  }

  .depositeSection {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 24px;
    gap: 20px;

    .redeemYour {
      width: 100%;
      background-color: $white-color;
      padding: 16px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 8px;
      }

      .redeemTitle {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 700;
        color: $main-color;

        @media screen and (max-width: $breakpoint-small) {
          margin-left: 12px;
          font-size: 15px;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: $breakpoint-large) {
      flex-wrap: wrap;
    }

    .cardLeft {
      width: 70%;

      @media screen and (max-width: $breakpoint-large) {
        width: 100%;
      }

      .innerCard {
        margin-bottom: 20px;

        .statContainer {
          .stats {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: $title-color;
            text-align: start;
          }

          .statDEtail {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .statItem {
              width: 25%;
              padding: 20px 0px;
              text-align: start;

              @media screen and (max-width: $breakpoint-small) {
                width: 50%;
              }

              .marketCap,
              p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                align-items: center;
                color: $tableheader;
              }

              .calValue,
              h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                color: $title-color;
                margin-top: 12px;
              }
            }
          }
        }

        .topsectionToken {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $input-border;
          padding: 17px 0;

          @media screen and (max-width: $breakpoint-small) {
            flex-wrap: wrap;
            align-items: flex-start;
          }

          .serialNo {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.28px;
            color: $main-color;
            width: 5%;
            text-align: left;

            @media screen and (max-width: $breakpoint-small) {
              width: 50%;
            }
          }

          .marketValue {
            font-size: 14px;
            color: $main-color;
            line-height: 140%;
            width: 25%;
            text-align: left;

            @media screen and (max-width: $breakpoint-small) {
              width: 50%;
            }
          }

          .percentage {
            width: 35%;

            @media screen and (max-width: $breakpoint-small) {
              width: 50%;
            }
          }

          .usdsIncon {
            display: flex;
            align-items: center;
            width: 20%;

            @media screen and (max-width: $breakpoint-small) {
              width: 50%;
              flex-wrap: wrap;
            }

            .usdctile {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
              font-size: 14px;
              color: $main-color;
              font-weight: 700;
              letter-spacing: 0.28px;
              margin-left: 12px;
            }
          }

          .posName {
            color: $main-color;
            @media screen and (max-width: $breakpoint-small) {
              width: 100%;
              text-align: left;
              margin-bottom: 6px;
              margin-top: 6px;
            }
          }
        }
      }

      .allocationsTitle {
        h2 {
          margin-bottom: 17px;
          font-size: 18px;
        }
      }

      .allocationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0 24px;

        .allocationHeaderHead {
          text-align: left;
        }

        .poshead {
          width: 6%;

          @media screen and (max-width: $breakpoint-large) {
            width: auto;
          }
        }

        .namehead {
          width: 18%;

          @media screen and (max-width: $breakpoint-large) {
            width: auto;
          }
        }

        .capitalizationhead {
          width: 25%;

          @media screen and (max-width: $breakpoint-large) {
            width: auto;
          }
        }

        .volumehead {
          width: 25%;

          @media screen and (max-width: $breakpoint-large) {
            width: auto;
          }
        }

        .percentagehead {
          width: 35%;

          @media screen and (max-width: $breakpoint-large) {
            width: auto;
          }
        }
      }
    }

    .cardRight {
      width: 30%;

      @media screen and (max-width: $breakpoint-large) {
        width: 100%;
      }

      .coinDetail {
        position: relative;

        .exchangeIcon {
          position: absolute;
          top: 40%;
          right: 45%;
        }
      }

      .btn {
        margin-top: 32px;
      }
    }

    .swapContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      .swapTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $title-color;
      }
    }

    .amountContainer {
      background: $light-gray;
      border: 1px solid $input-border;
      border-radius: 12px;
      margin-bottom: 12px;
      padding: 12px;

      .amountTop {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .amountTitle {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $title-color;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $input-label;
        }
      }

      .amountBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 29px;
          color: $input-placeholder;
        }

        .amountBottomRight {
          display: flex;
          align-items: center;

          .coin {
            max-width: 96px;
            width: 100%;
            height: 32px;
            background: $white-color;
            border: 1px solid $purple-light;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            margin-left: 12px;
            padding: 2px 12px;

            p {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              align-items: center;
              letter-spacing: 0.02em;
              color: $btn-text-color;
            }
          }

          .max {
            background: $white-color;
            border-radius: 24px;
            width: 49px;
            height: 24px;

            span {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.02em;
              color: $color-yellow;
            }
          }
        }
      }
    }
  }
}

@import "../../styles//variables.scss";
.estimate {
    max-width: 600px;
    width: 100%;
    background: var(--white-color);
    box-shadow: 0px 15px 50px -12px rgba(109, 112, 116, 0.16);
    border-radius: 10px;
    padding: 48px 60px;
    margin: auto;

    @media screen and(max-width:$breakpoint-medium) {
        padding: 16px;

    }

    .estimateLink {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;

        a {
            padding-right: 8px;
            padding-bottom: 5px;
            border-bottom: 1px dashed var(--input-border);
            color: var(--peragraph-text);
            font-size: 16px;
            font-weight: 400;
            
        }
    }

    .estimtCard {
        padding: 12px;
        background: var(--white-color);
        border: 1px solid var(--input-border);
        border-radius: 12px;
        margin-bottom: 12px;

        .crytoValue {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8px;

            h6 {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: var(--text-dark);
            }

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 29px;
                color: #B5B8CB;
            }

            .optons {
                position: relative;
                select {
                    width: 96px;
                    height: 32px;
                    background: var(--white-color);
                    border: 1px solid #E8E1F6;
                    border-radius: 24px;
                    font-weight: 700;
                    font-size: 14px;
                    color: #4E307A;
                    padding: 0 19px;
                    outline: none;
                    cursor: pointer;
                }
                svg{
                    position: absolute;
                    right: 19px;
                    top: 13px;
                }
            }

        }
    }

    .cryptoBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        flex-wrap: wrap;

        .btnChild {
            width: 48%;
            @media screen and (max-width:$breakpoint-medium) {
                width: 100%;
                margin-bottom: 16px;
            }
        }
    }
}
@import "../../../styles/variables";
.selectToken {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  width: 50%;
  @media screen and (max-width: $breakpoint-xsmall) {
    width: 100%;
  }
  .selecttex {
    margin-left: 12px;
    .tokenTitle {
      font-size: 15px;
      color: $title-color;
      font-weight: 700;
      line-height: 140%;
    }
    .tokencontent {
      font-size: 14px;
      color: $peragraph-text;
      font-weight: 400;
      line-height: 140%;
    }
  }
}

@import "../../styles/variables.scss";

.walletCard {
  text-align: center;
  position: relative;

  min-width: 261px;

  // max-height: 261px;
  @media screen and (max-width: $breakpoint-medium) {
    max-width: 100%;
  }

  .icon {
    svg {
      height: 50px;
      width: 50px;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $title-color;
    font-weight: bold;
    margin: 20px 0px;
  }

  h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $btn-text-color;
    margin-bottom: 16px;
    height: 42px;
  }

  p {
    width: 60%;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    color: $main-color-70;
    margin: 0px auto;
    margin-bottom: 24px;
  }

  button {
    background: $btn-text-color;
    border-radius: 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $white-color;
    outline: none;
    width: 100%;
    border: 0;
    padding: 7px;
    cursor: pointer;
  }

  .icon2 {
    position: absolute;
    right: 0px;
    top: 0px;
    svg {
      fill: $main-color-50;
    }
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 16px;
  justify-content: flex-start;
  .header {
    width: 100%;
    margin-bottom: 16px;

    h3 {
      color: $title-color;
      font-size: 28px;
      margin-bottom: 16px;
    }

    p {
      color: $peragraph-text;
      font-size: 16px;
    }
  }

  .buttonWrapper {
    width: 100%;
  }

  .button {
    display: flex;
    width: 536px;
    height: 72px;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #dddeed;
    background: #fff;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    text-transform: unset;
    flex-direction: column;

    h6 {
      font-size: 16px;
      color: $title-color;
    }

    p {
      color: $peragraph-text;
      font-size: 14px;
      margin-top: 2px;
      text-align: left;
    }

    &.active {
      border: 1px solid $title-color;
      background: $table-row-bg;
    }
  }

  .action {
    gap: 16px;
    margin-top: 16px;
  }
}

@import "../../styles/variables.scss";

.RadioBtn {
    display: flex;
    align-items: flex-start;

    label {
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: $input-label;
        padding-left: 10px;
    }

    input {
        width: 60px;
    }
}
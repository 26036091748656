@import "../../styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px 75px 0px 75px;
  @media (max-width: $breakpoint-medium) {
    padding: 48px 20px 0px 20px;
  }
  @media (max-width: $breakpoint-small) {
    padding: 20px 16px 0;
  }
}

@import "../../styles/variables.scss";

.inputContainer {
  width: 100%;
  margin-bottom: 16px;

  .labelError {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .optional {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .error {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $text-danger;
    margin-right: 15px;
  }

  .selectBox {
    width: 100%;
    border-radius: 12px;
    outline: none;
    padding: 40px;
    margin-top: 8px;
    background: url("../../assets/icons/arrowDown.svg") no-repeat 95% 50%;
    outline-width: 0;
    display: block;
    font-size: 14px;

    // &:focus {
    //     border: 1px solid $title-color
    // }
  }

  .borderError {
    border: 1px solid $text-danger;
    border-radius: 12px !important;

    &:focus {
      // border: 1px solid $input-border;
    }
  }

  // .borderNormal {
  //     border: 1px solid $input-border;
  // }
}

.star::after {
  content: "*";
  color: $text-danger;
  margin-left: 3px;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: $input-label;
}

select {
  background-color: $light-gray !important;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  color: $input-placeholder;
}

// select option:checked,
// select option::before {
//     background-color: var(--btn-light-border) !important;
//     color: var(--color-white);
// }

// select option::before {
//     background-color: red !important;
//     color: var(--color-white);
// }

// select option:checked,
// select option:hover {
//     background-color: red;
//     box-shadow: 0 0 10px 100px #000 inset;
// }

// select option:hover {
//     background-color: red !important;
//     /* Replace with your desired background color */
// }
// select.decorated option:hover {
//     box-shadow: 0 0 10px 100px red inset;
//     background-color: green;

// }

@import "../../styles/variables.scss";

.title {
  h2 {
    margin-top: 0px;
  }
}

.card {
  max-width: 600px;
  width: 100%;
  background: $color-white;
  box-shadow: 0px 15px 50px -12px rgba(109, 112, 116, 0.16);
  border-radius: 10px;
  padding: 48px 60px;
  margin: auto;

  @media screen and(max-width:$breakpoint-medium) {
    padding: 16px;
  }
}

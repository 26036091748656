@import "../../../styles/mixins";
@import "../../../styles/variables";
.productHelp {
  margin-top: 32px;

  .gridsystem {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 5px;
    align-items: center;
    margin-bottom: 50px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  .rightIcon {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .stakeYour {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $main-color-20;
    border-radius: 10px;
    margin-bottom: 32px;
    @media screen and (max-width: 767px) {
      padding: 24px;
    }
    .stakeYourText {
      width: 60%;
      padding: 40px 0 40px 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
      }
      .bottomContent {
        margin-top: 16px;
        @media screen and (max-width: $breakpoint-small) {
          margin-top: 12px;
        }
      }
    }
    .stakeYourImg {
      width: 40%;
      text-align: center;
      @media screen and (max-width: 767px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }
  }
  .contentFlexBox {
    flex-wrap: wrap;
    align-items: flex-start;
    .investmentButton {
      max-width: 244px;
      margin-left: 20px;
      a {
        color: $color-white;
      }
      @media screen and (max-width: $breakpoint-allmobile) {
        margin-left: 0px;
      }
      @media screen and (max-width: $breakpoint-small) {
        max-width: 100%;
      }
    }
    .investmentContent {
      width: 50%;
      position: relative;
      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        margin-bottom: 20px;
      }
      .investPeragraph {
        font-size: 15px;
        color: $peragraph-text;
        font-weight: 500;
        line-height: 140%;
        margin-left: 12px;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 5px;
          background-color: $color-yellow;
          border-radius: 8px;
        }
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;

  label {
    line-height: normal;
    padding-left: 12px;
    cursor: pointer;
  }
  .customcheckbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

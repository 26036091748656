@import "../../styles/variables";
.mainBox {
  background: $white-color;
  border-radius: 10px;
  max-width: 354px;
  width: 100%;
  min-height: 461px;
  text-align: center;
  transition: 0.5s;
  padding: 0 32px 32px;
  margin-top: 22px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    padding: 0 24px 24px;
    max-width: 100%;
    margin-bottom: 24px;
  }

  &:hover img {
    transform: scale(1.1);
  }

  h3 {
    margin-top: 28px;
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: $title-color;

    @media screen and (max-width: 767px) {
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $input-label;
  }

  .image {
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    margin-top: -20px;

    img {
      width: 100%;
      transition: 0.5s;
    }
  }

  .totalStake {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 32px;

    .totalDetails {
      text-align: center;
      width: 50%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $input-placeholder;
        font-size: 12px;
        margin-bottom: 6px;
        font-weight: 500;

        svg {
          padding-left: 6px;
          width: 22px;
          height: 22px;
        }
      }

      strong {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: $title-color;
      }
    }

    .boxBorder {
      height: 34px;
      border: 1px solid $input-border;
      margin-left: -20px;
    }
  }
}

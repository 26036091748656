@import "../../styles/variables.scss";

.container {
  padding: 20px;
  text-align: center;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $title-color;
    margin-bottom: 16px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $input-label;
    margin-bottom: 40px;
  }

  .otpInput {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
  }

  .inputWrapper {
    width: 20px;
  }

  .otpp {
    margin-bottom: 46px;

    .inputOtp {
      width: 40px !important;
      border: 1px solid $input-border !important;
      border-radius: 12px !important;
      outline: none !important;

      &:focus {
        border: 1px solid $title-color;
      }
    }

    .reactOtp {
      width: 100% !important;
      gap: 6px;
      justify-content: center !important;
    }
  }

  .resendBtnMobile {
    display: none;
  }

  .resendBtn {
    display: flex;
    align-items: center;
    flex-direction: column;

    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: $color-yellow;
      cursor: pointer;
    }
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18%; // 30%

    .btn {
      width: 242px;
    }
  }
}

.modal {
  padding: 20px;
  text-align: center;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: $title-color;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $input-label;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .modalBtn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }
}

@media (max-width: $breakpoint-small) {
  form {
    padding: 0px 20px;
  }

  .btnContainer {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 24px !important;

    .btn {
      width: 100% !important;
    }
  }

  .container {
    .otpp {
      margin-bottom: 20px;
    }
  }

  .otpp {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    .inputOtp {
      width: 60px !important;
      justify-content: space-between !important;
    }

    .reactOtp {
      width: 100% !important;
      justify-content: center !important;
      gap: 10 !important;
    }
  }

  .container {
    .resendBtn {
      display: none;
    }

    p {
      margin-bottom: 10px;
    }

    .resendBtnMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;

      h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: $color-yellow;
        cursor: pointer;
        margin: 10px 0px;
      }
    }
  }

  .modal {
    .modalBtn {
      flex-direction: column-reverse;
    }
  }
}

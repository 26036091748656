@import "../../../styles/variables";

.fundLimit {
  background-color: $color-white;
  border-radius: 10px;
  padding: 22px 27px;
  margin-top: 20px;

  h3 {
    color: $title-color;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 35px;
  }

  .detailInfo {
    flex: 1;

    p {
      color: $tableheader;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    h4 {
      color: $title-color;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 8px;
    }
  }
}

@import "../../styles/variables.scss";

.paymentInfo {
    max-width: 756px;
    width: 100%;
    height: auto;
    margin: auto;
    background: var(--white-color);
    border-radius: 10px;
    padding: 48px 60px;

    @media screen and (max-width:$breakpoint-large) {
        padding: 32px 20px;
    }

    .paymentTitle {
        h2 {
            margin-bottom: 12px;
        }
    }

    .paymentForm {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 32px;

        .cardDetails {
            width: 60%;
            margin-bottom: 24px;

            @media screen and (max-width:$breakpoint-medium) {
                width: 100%;
            }

            .paymentExpiry {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .expiryInput {
                    width: 48%;
                }
            }

            .credit {
                margin-bottom: 24px;
            }

            .mobileVisaCard {
                display: none;

                @media screen and (max-width:$breakpoint-medium) {
                    display: block;
                    width: 100%;
                    margin-bottom: 24px;
                }
            }
        }

        .visaCard {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width:$breakpoint-medium) {
                display: none;
            }

            button {
                background: transparent;
                border: 0;
                padding-left: 16px;

                @media screen and (max-width:$breakpoint-medium) {
                    padding-left: 0px;
                    padding-right: 16px;
                }
            }
        }
    }

    .cryptoBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 32px;

        .btnChild {
            width: 48%;

            @media screen and (max-width:$breakpoint-medium) {
                width: 100%;
                margin-bottom: 16px;
            }
        }
    }

    .checkBoxGroup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 16px 0;
        border-top: 1px solid #DDDEED;

        &:nth-last-child(2) {
            border-bottom: 1px solid #DDDEED;
        }
    }
}
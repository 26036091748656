@import "../../styles/variables";
.chooseChain {
  background-color: $table-row-bg;
  padding: 12px;
  border-radius: 12px;
  max-width: 202px;
  width: 100%;
  @media screen and (max-width: $breakpoint-small) {
    max-width: 100%;
  }
  .from {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: $title-color;
  }
  .selectchain {
    display: flex;
    align-items: center;
    cursor: pointer;
    .selectdropMenu {
      margin-left: 12px;
      svg {
        margin-left: 12px;
      }
    }
  }
}

// @import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700;900&family=Roboto:ital,wght@0,100;0,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,900&display=swap");
@import "./styles/mixins.scss";
@import "./styles/variables.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Hide Scrollbar for IE and Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  overflow-x: hidden;
  // font-family: "Red Hat Display" !important;
  // font-family: 'Nunito Sans', sans-serif !important;
}

* {
  @include fontFamily;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-shadow: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-qbdosj-Input {
  margin: -2px !important;
}

.opacityFull {
  opacity: 1 !important;
}

::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.css-1dimb5e-singleValue {
  margin-left: 5px !important;
}

.react-responsive-modal-modal {
  border-radius: 30px;
  max-width: 600px;
  display: inline-block !important;
  width: 90%;
}

.react-responsive-modal-container {
  // overflow: hidden !important;
}

.css-13cymwt-control:hover {
  border-color: none;
}

.flex {
  display: flex;
  flex: 1;
}

.j-center {
  justify-content: center;
}

.j-between {
  justify-content: space-between;
}

.a-center {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.material-ui-tab .css-108rbcv-MuiTabs-root button {
  text-transform: initial;
}

@media screen and (max-width: 1200px) {
  .material-ui-tab .css-108rbcv-MuiTabs-root {
    position: static;
  }

  .material-ui-tab .css-108rbcv-MuiTabs-root button {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .react-responsive-modal-modal {
    width: 100%;
  }
}

@media screen and (max-width: 757px) {
  .Indicator-Tokens .css-108rbcv-MuiTabs-root button {
    margin-right: 0;
    font-size: 14px;
    min-width: 70px;
  }
}

.MuiStepConnector-root {
  left: calc(-50% + 12px) !important;
  right: calc(50% + 12px) !important;
}

.rdt_TableHeadRow {
  border-bottom: 0px !important;
}

.react-date-picker__wrapper {
  border: none !important;
  width: 100%;
}

.react-date-picker__inputGroup__input .react-date-picker__inputGroup__year {
  border: none;
}

.react-calendar__tile--active {
  background-color: $title-color !important;
}

.react-calendar__tile--now {
  background-color: $select-bg !important;
}

.react-date-picker__inputGroup__input .react-date-picker__inputGroup__day {
  border: none !important;
}

.accordionSection .jrYwzF {
  border-bottom: 0;
  background-color: $table-row-bg;
}

.accordionSection .kvuIvK {
  background-color: transparent;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 1px 2px -3px rgba(0, 0, 0, 0.01),
    0px 0px 2px 1px rgba(0, 0, 0, 0.01), 0px 2px 3px 1px rgba(0, 0, 0, 0.06) !important;
}

.lmojr {
  z-index: 1 !important;
}

.accordionSection .jCBhQi {
  overflow-x: hidden;
}

.accordionSection .fpAVjd {
  background-color: $table-row-bg;
}

.MuiAccordionSummary-root {
  align-items: flex-start;
}

.stepperInnerSection {
  @media screen and (max-width: 767px) {
    .css-n7tliy-MuiStep-root {
      z-index: 2;
    }

    .css-zpcwqm-MuiStepConnector-root {
      position: static;
      top: 0;
      left: 0;
      right: 0;

      &::after {
        content: "";
        position: absolute;
        left: 19px;
        top: -49px;
        height: 59px;
        width: 2px;
        background: #d4c7f0;
        z-index: -1;
      }

      .css-z7uhs0-MuiStepConnector-line {
        display: none;
      }
    }
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: $main-color $white-color;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0px;
  height: 100px;
}

*::-webkit-scrollbar-track {
  background: #ece8f2;
}

*::-webkit-scrollbar-thumb {
  background-color: $title-color;
  border-radius: 20px;
  border: 3px solid $white-color;
}

// Image cropper border radius
.cropper-crop {
  border-radius: 10px !important;
}

.transak_close:hover {
  background: #4e307a;
}

.css-18y2fhi-placeholder {
  margin-left: 6px !important;
}

// affiliateTable code table scss
.affiliateTable {
  .buGObk {
    border: 0 !important;

    .SoHcp {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #9599b5 !important;
      background-color: transparent !important;
    }
  }

  .rdt_TableBody {
    background-color: #fff;

    .jBKeyo {
      background-color: #f9f7fc !important;
      border: 0 !important;
      border-radius: 12px !important;

      &:hover {
        background-color: #f9f7fc !important;
      }

      .hecapG {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #190044;
      }
    }
  }
}

.loadingContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdt_TableBody {
  margin-top: 12px;
}

// Top dropdown on avatar click
// .css-nc4rdv-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
//   width: 150px !important;
// }

// .css-gofo6c-MuiButtonBase-root-MuiMenuItem-root {
//   font-size: 14px !important;
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center !important;
// }

@import "../../../styles/variables";
.monthlyPlan {
  .lookinginvestment {
    width: 70%;
    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
    }
    .investmentText {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $peragraph-text;
      margin-bottom: 16px;
      @media screen and (max-width: $breakpoint-medium) {
        margin-bottom: 12px;
      }
    }
  }
  .quickLinks {
    width: 25%;
    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
    }
    .quckTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $title-color;
      margin-bottom: 20px;
      @media screen and (max-width: $breakpoint-medium) {
        margin-bottom: 12px;
      }
    }
    .linksText {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: $peragraph-text;
      margin-bottom: 16px;
      display: block;
      @media screen and (max-width: $breakpoint-medium) {
        margin-bottom: 12px;
      }
    }
  }
  .flexMonthly {
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .TokensLink {
    margin: 0;
  }
  .removeIcon {
    .SavingPlanBox_plusicon__ugunj {
      display: none;
    }
  }
  .selectPlanBox {
    max-width: 100%;
    h3 {
      color: $btn-text-color;
    }
  }
  .planFlex {
    gap: 24px;
    padding: 0;
    @media screen and (max-width: $breakpoint-large) {
      flex-wrap: wrap;
      gap: 16px;
    }
    .autoInvestBox {
      max-width: 31.33%;
      @media screen and (max-width: $breakpoint-large) {
        max-width: 48%;
      }
      @media screen and (max-width: $breakpoint-small) {
        max-width: 100%;
      }
    }
  }
  .investingButton {
    max-width: 290px;
    margin-top: 32px;
    height: 40px;
    @media screen and (max-width: $breakpoint-small) {
      max-width: 100%;
    }
  }
  .investingTitle {
    margin-bottom: 24px;
    max-width: 523px;
    width: 100%;
    @media screen and (max-width: $breakpoint-medium) {
      max-width: 100%;
    }
  }
}

.title {
  h2 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 16px;
  }
}

.flex {
  gap: 20px;
  margin-top: 20px;
}
@import "../../styles/variables.scss";

.dateMain {
  margin-bottom: 16px;
}

.datePicker {
  width: 100%;
  border: 1px solid $input-border;
  border-radius: 12px;
  padding: 9px;
  margin-top: 8px;
  cursor: pointer;
  margin-bottom: 6px;
  background: #fafafa !important;
  color: $input-label;
}

.star::after {
  content: "*";
  color: $text-danger;
  margin-left: 3px;
}

// .active {
//     border: 1px solid $title-color
// }

@import "../../styles/variables.scss";

.boarding {
  // max-width: 900px;
  position: relative;
  height: 100vh;

  .formWrapper {
    max-width: 900px;
    margin: 0px auto;

    .stepper {
      margin-top: 20px; // 40px
      margin-bottom: 25px; // 50px

      .stepperLabel {
        white-space: nowrap;
      }
    }

    .avtiveIcon {
      fill: $title-color;
      border: 2px solid $title-color;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 4px;
    }

    .completed {
      fill: $title-color;
    }

    .iconText {
      fill: $color-white;
    }

    .iconNotActive {
      fill: #d4c7f0;
    }
  }
}

@media (max-width: $breakpoint-medium) {
  .stepper {
    margin-left: 20px;
    margin-top: 26px;
  }
}

@media (max-width: 700px) {
  .formWrapper {
    .stepperContainer {
      overflow-x: scroll;
    }
  }
}

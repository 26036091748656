@import "../../../styles/variables";

.myWallet {
  margin-top: 32px;

  @media screen and (max-width: $breakpoint-small) {
    margin-top: 16px;
  }

  .mywalletCard {
    padding: 16px;
    background-color: $white-color;
    border-radius: 10px;

    @media (max-width: $breakpoint-small) {
      flex-wrap: wrap;
      padding: 12px;
    }

    @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
      flex-wrap: wrap;
      padding: 12px;
    }

    .buttonGroups {
      justify-content: flex-end;

      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
      }

      @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
        width: 100%;
      }

      .addButton {
        height: 32px;
        max-width: 146px;
        width: 100%;
        margin-right: 12px;
        font-size: 14px;

        @media screen and (max-width: $breakpoint-small) {
          margin-right: 8px;
          max-width: 50%;
        }

        @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
          max-width: 50%;
        }
      }

      .backArrowIcon {
        color: "#4e307a";
        margin-right: 12px;
        cursor: pointer;
      }

      .withdraw {
        height: 32px;
        max-width: 146px;
        width: 100%;
        font-size: 14px;

        @media screen and (max-width: $breakpoint-small) {
          max-width: 50%;
        }

        @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
          max-width: 50%;
        }
      }
    }

    .walletBalanceIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $light-gray1;
      padding: 6px;
      border-radius: 12px;
      margin-right: 16px;

      @media screen and (max-width: $breakpoint-small) {
        margin-right: 12px;
      }
    }

    .walletBalance {
      font-size: 12px;
      color: $peragraph-text;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 6px;

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 4px;
      }
    }

    .wallerPrice {
      font-size: 24px;
      color: $title-color;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.48px;

      @media screen and (max-width: $breakpoint-small) {
        font-size: 20px;
      }
    }

    .avlWalletBal {
      margin-left: 25px;
    }

    .tokenPrice {
      font-size: 14px;
      color: $title-color;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.48px;
    }

    .cardCentertext {
      justify-content: space-around;

      @media screen and (max-width: $breakpoint-small) {
        justify-content: flex-start;
        border-top: 1px solid $input-border;
        padding-top: 20px;

        .centerText {
          width: 50%;
        }
      }
    }

    .buy {
      max-width: 110px;
      width: 100%;
      margin-right: 16px;
      height: 40px;

      @media screen and (max-width: $breakpoint-small) {
        margin-right: 12px;
        max-width: 50%;
        height: 32px;
        font-size: 14px;
      }
    }

    .sell {
      max-width: 110px;
      width: 100%;
      height: 40px;

      @media screen and (max-width: $breakpoint-small) {
        max-width: 50%;
        height: 32px;
        font-size: 14px;
      }
    }
  }

  .walletSpace {
    margin-bottom: 16px;

    .wallerTitle {
      font-size: 18px;
      color: $title-color;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.48px;

      @media screen and (max-width: $breakpoint-small) {
        font-size: 16px;
      }
    }
  }
}

@import "../../../styles/variables";
.nftsTokenpage {
  margin-top: 32px;
  .graphSection {
    padding: 24px;
    background-color: $white-color;
    border-radius: 12px;
    margin-bottom: 24px;
    .graphImg {
      width: 100%;
      height: auto;
    }
  }
  .depositeSection {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    @media screen and (max-width: $breakpoint-small) {
      flex-direction: column;
      flex-wrap: wrap;
    }
    .redeemYour {
      width: 49%;
      background-color: $white-color;
      padding: 16px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      @media screen and (max-width: $breakpoint-small) {
        width: 100%;
        margin-bottom: 8px;
      }
      .redeemTitle {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 700;
        color: $title-color;
        @media screen and (max-width: $breakpoint-small) {
          margin-left: 12px;
          font-size: 15px;
        }
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 20px;
    @media screen and (max-width: $breakpoint-large) {
      flex-wrap: wrap;
    }
    .cardLeft {
      width: 70%;
      @media screen and (max-width: $breakpoint-large) {
        width: 100%;
      }
      .innerCard {
        margin-bottom: 20px;

        .statContainer {
          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
            color: $title-color;
            text-align: start;
          }

          .statDEtail {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .statItem {
              width: 25%;
              padding: 20px 0px;
              text-align: start;
              @media screen and (max-width: $breakpoint-small) {
                width: 50%;
              }
              p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                align-items: center;
                color: $tableheader;
              }

              h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                color: $title-color;
                margin-top: 12px;
              }
            }
          }
        }
      }
      .accordiongape {
        margin-bottom: 12px;
        padding: 16px 24px;
        .customAccordion {
          box-shadow: none;
          padding: 0;

          .accordionTitle {
            text-align: left;
            padding: 0;
          }
          .AccordionCard {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            height: 250px;
            padding: 0 5px;
            overflow-x: scroll;
            background-color: #f6f6f7;
            gap: 8px;
            .cardItems {
              width: auto;
              @media screen and (max-width: $breakpoint-allmobile) {
                width: auto;
              }
            }
          }
        }
      }
      .allocationsTitle {
        h2 {
          margin-bottom: 17px;
          font-size: 18px;
        }
      }
      .allocationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .allocationHeaderHead {
          width: 20%;
          text-align: left;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $tableheader;
            margin-bottom: 10px;
          }
        }
      }
    }
    .cardRight {
      width: 30%;
      @media screen and (max-width: $breakpoint-large) {
        width: 100%;
      }
      .coinDetail {
        position: relative;

        .exchangeIcon {
          position: absolute;
          top: 40%;
          right: 45%;
        }
      }

      .btn {
        margin-top: 32px;
      }
    }

    .swapContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $title-color;
      }
    }
    .amountContainer {
      background: $light-gray;
      border: 1px solid $input-border;
      border-radius: 12px;
      margin-bottom: 12px;
      padding: 12px;

      .amountTop {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $title-color;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: $input-label;
        }
      }

      .amountBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 29px;
          color: $input-placeholder;
        }

        .amountBottomRight {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 150px;

          .coin {
            max-width: 96px;
            width: 100%;
            height: 32px;
            background: $white-color;
            border: 1px solid $purple-light;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-left: 12px;

            p {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              align-items: center;
              letter-spacing: 0.02em;
              color: $btn-text-color;
            }
          }

          .max {
            background: $white-color;
            border-radius: 24px;
            width: 49px;
            height: 24px;

            span {
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.02em;
              color: $color-yellow;
            }
          }
        }
      }
    }
  }
}
.customeAccordionIcon {
  @media screen and (max-width: $breakpoint-small) {
    position: relative;
    bottom: -80px;
  }
}

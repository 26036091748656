@mixin fontFamily {
  font-family: "Red Hat Display";
}

@mixin flexProperty {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

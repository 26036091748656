@import "../../styles/variables.scss";

.card {
  min-height: 330px !important;
}

.productContainer {
  padding: 20px;
  .graphContainer {
    width: 60%;
    margin-bottom: 20px;

    .cardInner {
      .svg {
        width: 100%;
      }

      .cardInnerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cardInnerContentLeft {
          width: 100%;
          text-align: left;
          margin-bottom: 24px;

          .priceMob {
            display: none;

            .priceContainerMob {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              h6 {
                margin: 0px;
              }
            }
          }

          .priceContainer {
            display: flex;
            align-items: center;
            gap: 5px;

            h3 {
              font-style: normal;
              font-weight: 800;
              font-size: 40px;
              line-height: 53px;
              letter-spacing: 0.02em;
              color: var(--dark-blue);
              margin-bottom: 6px;
            }

            span {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              letter-spacing: 0.02em;
              color: var(--dark-blue);
              margin-top: 10px;
            }
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: var(--peragraph-text);
            margin-bottom: 8px;
          }

          h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: var(--color-blue);
            margin-bottom: 20px;
          }
        }

        .cardInnerContentRight {
          display: flex;
          align-items: center;
          gap: 20px;

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: $title-color;
          }

          .active {
            width: 40px;
            height: 22px;
            background: #1e1e1e;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white-color;
          }
        }
      }
    }
  }

  .reward {
    width: 100%;
    margin-top: 20px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--dark-blue);
    text-align: start;
  }

  .tokenContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;

    .transWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 12px;
      max-height: 1010px;
      overflow-y: scroll;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        background-color: #fff;

        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        color: var(--color-orange);
        padding: 5px 10px;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark-blue);
      text-align: start;
    }

    .tokenWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .tranContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h5 {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: var(--dark-blue);
        }

        .tranHead {
          h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: var(--dark-blue);
          }

          p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: var(--input-label);
            text-align: left;
            margin-top: 4px;
          }
        }
      }

      .tokenContent {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;

        .tokenItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }

        h4 {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: var(--dark-blue);
          text-transform: uppercase;
        }

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: var(--color-blue);
        }

        .arrowUp {
          margin-right: 5px;
          margin-bottom: 2px;
        }

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: var(--input-label);
          margin-left: 4px;
        }

        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: var(--dark-gray);
        }

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: var(--dark-blue);
        }
      }
    }
  }

  .transactionContainer {
    width: 40%;
  }

  .iconContainer {
    width: 40px;
    height: 40px;
    background-color: var(--table-row-bg);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.claim {
  width: 100px;

  .claimBtn {
    height: 32px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: var(--btn-text-color);
  }
}

.reward1 {
  width: 60% !important;
  margin-top: 20px;
}

.balance {
  width: 40% !important;
  margin-top: 20px;

  .token {
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark-blue);
      margin: 24px 0px 16px 0px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
      color: var(--peragraph-text);
    }

    span {
      color: var(--color-orange);
      font-weight: bold;
    }
  }
}

.balanceWrapper {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--peragraph-text);
    text-align: left;
  }
}

.balanceContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balanceContentLeft {
  display: flex;
  align-items: center;
  gap: 5px;

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: var(--dark-blue);
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: var(--dark-blue);
  }
}

.balanceContenRight {
  width: 100px;
}

.flexContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.stakCard {
  .rowMobileContainer {
    display: none;
  }

  .stakCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark-blue);
      text-align: start;
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: var(--color-orange);
      cursor: pointer;
    }
  }
}

.stakHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;

  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--dark-gray);
  }
}

.flex {
  gap: 20px;
  flex-wrap: wrap;
  .cryptocurrencies {
    @media screen and (min-width: 1200px) {
      p {
        min-height: 105px;
      }
    }
    @media screen and (min-width: 1580px) {
      p {
        min-height: inherit;
        height: inherit;
      }
    }
    @media screen and (max-width: 1200px) {
      p {
        height: inherit;
      }
    }
  }
}

@media (max-width: 1150px) {
  .cardLeft {
    width: 100% !important;
  }

  .cardRight {
    width: 100% !important;
  }

  .cardInnerContent {
    flex-direction: column;
  }

  .cardInnerContent {
    align-items: flex-start !important;
  }
}

@media (max-width: $breakpoint-medium) {
  .productContainer {
    .graphContainer {
      width: 100%;
    }

    .tokenContainer {
      width: 100%;
    }
  }

  .stakHeader {
    display: none;
  }

  .stakWrapper {
    display: none;
  }

  .rowMobileContainer {
    display: block !important;
  }
}

@media (max-width: 1150px) {
  .flexContainer {
    flex-direction: column;
  }

  .reward1 {
    width: 100% !important;
  }

  .balance {
    width: 100% !important;
  }

  .graphContainer {
    width: 100% !important;
  }

  .tokenContainer {
    width: 100% !important;
  }

  .cardInnerContent {
    .cardInnerContentRight {
      width: 100% !important;
      justify-content: inherit !important;
    }
  }

  .priceMob {
    display: block !important;
  }

  .rate {
    display: none !important;
  }

  .priceContainer {
    span {
      display: none !important;
    }
  }
}

.textCenter {
  text-align: center;
}

@import "../../styles/variables";
.visaCard {
  display: flex;
  align-items: center;
  flex-direction: row;
  .visaButton {
    background-color: $white-color;
    margin-left: 16px;
    border: 1px solid $input-border;
    border-radius: 6px;
    cursor: pointer;
    // padding: 0px 10px;
    @media screen and (max-width: $breakpoint-medium) {
      margin-left: 0px;
      margin-right: 5px;
    }
  }
}

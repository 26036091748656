@import "../../colorVariables.scss";
@import "../../styles/variables.scss";

.stakeYour {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: $main-color-10;
  border-radius: 10px;
  margin-bottom: 32px;

  @media screen and (max-width: $breakpoint-medium) {
    padding: 24px;
  }

  .stakeYourText {
    width: 60%;
    padding: 40px 0 40px 30px;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0;
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: $title-color;
      margin-bottom: 20px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: $label-color;
    }

    .contentsec {
      margin-top: 16px;
    }
  }

  .stakeYourImg {
    width: 40%;
    text-align: center;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 24px;
    }

    img {
      width: 100%;
    }
  }

  .child_LG {
    @media screen and (max-width: $breakpoint-medium) {
      display: none !important;
    }
  }

  .child_SM {
    display: none !important;

    @media screen and (max-width: $breakpoint-medium) {
      display: block !important;
    }
  }
}

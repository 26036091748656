@import "../../styles/variables.scss";

.questionnaire {
  padding: 20px;

  .radioContainer {
    margin-bottom: 33px;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: $title-color;
    margin-bottom: 32px;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $title-color;
    margin-bottom: 17px;
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40%;
    margin-top: 50px;
    padding-bottom: 50px;

    @media screen and (max-width: $breakpoint-small) {
      margin-top: 32px;
      padding-bottom: 32px;
    }
  }

  .terms {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 32px;

    label {
      margin-top: 10px;
    }
  }

  .halfWidth {
    width: 50%;

    @media (max-width: $breakpoint-medium) {
      width: 100%;
    }
  }

  .checkbox {
    display: flex;
  }

  .rangInputField {
    margin-right: 0 !important;
  }

  .range {
    width: 45%;
    margin: 0px auto;

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #525885;
    }
  }

  hr {
    border: 1px solid $input-border;
  }

  .inputField {
    width: 100%;
  }

  .questionRadio {
    row-gap: 10px !important;
    margin-bottom: 20px;
  }

  .skipText {
    display: none !important;
  }

  .skip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 24px;
    }

    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      color: $color-yellow;
      cursor: pointer;
      margin: 0 !important;
    }

    h2 {
      margin: 0;
    }
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    color: $title-color;
    margin-top: 10px;
    text-align: center;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $title-color;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $peragraph-text;
  }

  .ok_btn {
    width: 220px;
    margin-top: 28px;

    @media (max-width: $breakpoint-small) {
      width: 100%;
      margin-top: 8px;
    }
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: $breakpoint-small) {
      width: 100%;
      flex-direction: column;
      gap: 0px;
    }
  }
}

.parentClass {
  margin-bottom: 2rem;
}

@media (max-width: $breakpoint-small) {
  .radioContainer {
    padding: 0px 10px;
  }

  .btnContainer {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .back {
    margin-top: 20px;
  }

  .questionnaire {
    .skip {
      h5 {
        display: none !important;
        color: $color-yellow;
      }
    }

    .skipText {
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-decoration-line: underline;
      color: $color-yellow;
      cursor: pointer;
      padding-bottom: 50px;
      margin-top: 34px;

      @media screen and (max-width: $breakpoint-small) {
        padding-bottom: 24px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .questionRadio {
      align-items: flex-start !important;
    }

    .range {
      width: 100%;
    }
  }
}

.datePicker {
  width: 100%;
  border: 1px solid $input-border;
  border-radius: 12px;
  padding: 9px;
  margin-top: 8px;
  cursor: pointer;
  margin-bottom: 6px;
  background: #fafafa !important;
}

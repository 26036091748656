$breakpoint-xsmall: 320px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-allmobile: 767px;
$breakpoint-extra-large: 1300px;
$breakpoint-double-extra-large: 1400px;

// =========== *** Color Variables *** ==================
$black-color: #000;
$light-black: #1e1e1e;
$white-color: #fff;
$light-gray: #fafafa;
$light-gray1: #f0f0f0;

$input-border: #dddeed;
$title-color: #000000;
$input-placeholder: hsla(200, 53%, 23%, 0.5);
$input-label: hsla(200, 53%, 23%, 0.7);
$btn-disabled: #e5e7eb;
$table-th: hsla(200, 53%, 23%, 0.5);

$title-color: #1b4458;
// $title-color-theme: #28255F;
$text-danger: #f97172;
$link-color: #ffb000;
$btn-text-color: #1b4458;
$title-color: #9c86ce;
$stepper-dark: #1b4458;
$stepper-light: hsla(200, 53%, 23%, 0.5);
$color-white: #ffffff;

$purple-light: #e8e1f6;
$title-color: #1b4458;
$btn-disabled-color: #9ca3af;
$color-orange: #f97172;
$color-yellow: #ffb000;
$color-blue: #3e3aff;

$table-row-bg: #f9f7fc;
$peragraph-text: hsla(200, 53%, 23%, 0.7);
$tableheader: hsla(200, 53%, 23%, 0.5);
$purple-Bg-Color: #eee9f6;
$gray-Bg-Color: #8da1ab;

$main-gradient: linear-gradient(
  140.74deg,
  #b1d0df 2.62%,
  #bad6e3 13.85%,
  #a8c8d8 25.74%,
  #b7d0dc 37.06%,
  #c2dae5 48.81%,
  #cbd3ec 58.88%,
  #bcc6e6 69.22%,
  #a5afd3 79.71%,
  #909dc7 89.08%,
  #a3aed1 98.97%
);

$main-color: #1b4458;
$main-color-70: hsla(200, 53%, 23%, 0.7);
$main-color-50: hsla(200, 53%, 23%, 0.5);
$main-color-20: hsla(200, 53%, 23%, 0.2);
$main-color-10: hsla(200, 53%, 23%, 0.1);
$dark-sortByYear: #1e1e1e;

$label-color: hsla(200, 53%, 23%, 0.7);
$select-bg: rgba(156, 134, 206, 0.6);

:export {
  breakpoint-xsmall: $breakpoint-xsmall;
  breakpoint-small: $breakpoint-small;
  breakpoint-medium: $breakpoint-medium;
  breakpoint-large: $breakpoint-large;
  breakpoint-extra-large: $breakpoint-extra-large;

  black-color: $black-color;
  white-color: $white-color;
  light-gray: $light-gray;
  input-border: $input-border;
  dark-blue: $title-color;
  input-placeholder: $input-placeholder;
  input-label: $input-label;
  btn-disabled: $btn-disabled;

  text-dark: $title-color;
  text-danger: $text-danger;
  btn-text-color: $btn-text-color;
  btn-light-border: $title-color;
  color-white: $color-white;

  purple-light: $purple-light;
  dark-sortByYear: $dark-sortByYear;
  btn-disabled-color: $btn-disabled-color;
  color-orange: $color-orange;
  color-yellow: $color-yellow;
  color-blue: $color-blue;
  main-gradient: $main-gradient;
  main-color: $main-color;
  main-color-70: $main-color-70;
  main-color-50: $main-color-50;
  main-color-20: $main-color-20;
  main-color-10: $main-color-10;
  table-row-bg: $table-row-bg;
  peragraph-text: $peragraph-text;
  title-color: $title-color;
}

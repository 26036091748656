@import "../../styles/variables.scss";

.menuItems {

    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 5px;
    // border-bottom: 1px solid red !important;

    span {
        font-size: 14px !important;
        font-weight: 500;
        color: $main-color;
        font-family: 'Nunito Sans', sans-serif !important;
    }

    svg {
        width: 20px;
        height: 20px;
    }
}
@import "../../styles/variables.scss";

.title {
    h2 {
        margin-top: 0px;
    }
}

.flex {
    gap: 20px;
    flex-wrap: wrap !important;
    margin-top: 20px;
}

.flex1 {
    align-items: flex-start !important;
}



.card {
    height: 169px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cardLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 100%;

        h3 {
            color: $black-color;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .iconContainer {
            background-color: #c6c6c6;
            border-radius: 12px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            width: 47px;
            height: 47px;
        }

        .btn {
            width: 132px;
            height: 40px;
        }

        @media screen and (max-width: 768px) {
            gap: 10px;
            flex-direction: column;
        }
    }

    .cardRight {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .btn {
            width: 190px;
            height: 40px;
        }

        img {
            height: 100%;
        }

        @media screen and (max-width: 768px) {
            gap: 10px;
            flex-direction: column;
        }
    }
}
@import "../../../styles/variables";

.overview {
  .interested {
    margin-bottom: 32px;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 24px;
    }
  }

  .overviewTitle {
    margin-bottom: 32px;

    @media screen and (max-width: $breakpoint-small) {
      margin-bottom: 40px;
    }

    h2 {
      margin-bottom: 16px;

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 12px;
      }
    }
  }

  .indicatorCard {
    display: flex;
    align-items: center;
    gap: 32px;
    padding: 20px 20px 20px 0;
    border-radius: 10px;
    background-color: $white-color;
    margin-bottom: 24px;
    margin-left: 10px;

    @media screen and (max-width: $breakpoint-large) {
      gap: 10px;
      margin-left: 0;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 24px;
    }

    @media screen and (max-width: $breakpoint-allmobile) {
      flex-wrap: wrap;
      padding: 24px;
    }

    .imgSection {
      position: relative;
      height: 251px;
      width: 251px;

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        height: 165px;
      }

      .img {
        top: 0;
        left: -10px;
        border-radius: 12px;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: $breakpoint-allmobile) {
          left: 0;
          top: -38px;
          height: 165px;
        }
      }
    }

    .toeknInformation {
      max-width: 390px;
      padding-top: 10px;

      @media screen and (max-width: $breakpoint-allmobile) {
        max-width: 100%;
      }
    }

    .rightBorder {
      width: 1px;
      height: 200px;
      background-color: $input-border;

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        height: 1px;
      }
    }
  }

  .comingSoonCard {
    align-items: flex-start;
    background-color: $white-color;

    @media screen and (max-width: $breakpoint-allmobile) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .comingSoonText {
      width: 80%;

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
        padding-left: 0;
      }
    }

    .gameTitle {
      h2 {
        margin-bottom: 12px;
      }

      p {
        font-size: 12px;
        color: #9ca3af;
      }
    }

    .comingtext {
      // margin-top: 50px;
      // margin-bottom: 30px;
      color: #9ca3af;
      align-items: center;
      display: flex;
      font-size: 16px;
      height: 170px;
      justify-content: center;

      @media screen and (max-width: $breakpoint-small) {
        height: 100px;
      }
    }
  }

  .quicklinks {
    background-color: $main-color-20;
    padding: 24px;
    border-radius: 10px;

    @media screen and (max-width: $breakpoint-small) {
      padding: 12px 24px;
    }

    .quickTitle {
      font-size: 16px;

      h2 {
        margin-top: 0;

        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 8px;
        }
      }
    }

    .quicklink {
      display: block;
      font-weight: 500;
      letter-spacing: 0.32px;
      font-size: 16px;
      color: $peragraph-text;
      margin-bottom: 16px;

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 8px;
      }
    }
  }

  .tokenDetails {
    .tokenTitle {
      margin-bottom: 16px;

      @media screen and (max-width: $breakpoint-small) {
        margin-bottom: 24px;
      }

      h2 {
        margin-bottom: 12px;
        font-size: 18px;
      }
    }

    .current {
      color: $main-color-50;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .currentt {
      color: $main-color-50;
      font-size: 12px;
      font-weight: 500;
    }

    .tokenValue {
      font-size: 28px;
      font-weight: 700;
      color: $title-color;
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      margin-bottom: 4px;

      .toeknprice {
        margin-left: 10px;
        font-weight: 500;
        color: $title-color;
        font-size: 14px;
      }
    }

    .updateValue {
      display: flex;
      align-items: center;
      flex-direction: row;

      .spanvalue {
        margin-right: 8px;
        font-size: 14px;
        font-weight: 500;
        color: $title-color;
      }
    }

    .buyTokenSec {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 12px;

      @media screen and (max-width: $breakpoint-small) {
        flex-direction: column;
        align-items: flex-start;
      }

      .buybutton {
        max-width: 139px;
        width: 100%;
        margin-left: auto;
        height: 40px;

        @media screen and (max-width: $breakpoint-small) {
          max-width: 100%;
          margin-bottom: 24px;
        }
      }

      .capitalization {
        font-size: 14px;
        color: $title-color;
        font-weight: 700;
        letter-spacing: 0.28px;
        display: block;
        margin-top: 4px;

        @media screen and (max-width: $breakpoint-small) {
          margin-bottom: 24px;
        }
      }
    }
  }
}

.cancelBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .cancelBtn {
    width: 50%;
  }
}

.tokenDescription {
  color: $peragraph-text;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 16px;

  .max {
    color: $color-yellow;
    font-weight: 700;
  }
}

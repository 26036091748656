@import "../../../styles//variables.scss";

.stakContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 20px;
  background-color: $table-row-bg;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 22px 16px;
  margin-top: 12px;
  height: 64px;
  word-break: break-all;

  .loanHead {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $main-color-70;
    margin: 0px !important;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: $title-color;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $input-label;
    margin-left: 6px;
  }
}

.claim {
  width: 100px;

  .claimBtn {
    height: 32px;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #4e307a;
  }
}

.stakRow {
  background: $table-row-bg;
  border-radius: 12px;
  padding: 16px 12px;
  margin-bottom: 12px;

  .fullWidth {
    width: 100% !important;
  }

  .flexBox {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .flex {
    margin-bottom: 10px;
    flex-wrap: nowrap;

    .content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: $breakpoint-small) {
      flex-direction: column;
    }
  }

  .claimBtn {
    height: 40px;
  }

  .cell {
    width: 50%;
    text-align: start;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $table-th;
    }

    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: $title-color;
      margin-top: 4px;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $peragraph-text;
    }
  }
}

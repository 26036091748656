@import "../../styles/variables.scss";

.card {
  text-align: center;
  position: relative;
  height: 285px;
  min-width: 256px;
  padding: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .cardBody {
    h4 {
      color: $title-color;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 18px;
    }
  }

  title {
    color: $black-color;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 18px;
  }

  .text {
    width: 60%;
    color: $peragraph-text;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin: 0px auto;
    margin-bottom: 16px;
    margin-top: 3px;
    line-height: 140%;
  }

  .button {
    background: $main-color;
    border-radius: 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $white-color;
    outline: none;
    width: 100%;
    border: 0;
    padding: 7px;
    cursor: pointer;
  }

  .inactive {
    opacity: 0.1 !important;
    pointer-events: none;
  }

  .toolTip {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: inherit;
  }
}

.comingSoon {
  border-radius: 10px;
  border: 1px solid #fff;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@import "../../styles/variables";

.token {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: $white-color;
  border-radius: 10px;
  padding: 12px;

  @media screen and (max-width: $breakpoint-small) {
    padding: 0;
  }

  .tokenIcon {
    display: flex;
    align-items: center;

    // width: 20%;
    @media screen and (max-width: $breakpoint-allmobile) {
      order: 1;
      width: 100%;
    }

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $title-color;
      padding-left: 12px;
    }

    .tokenBg {
      background-color: rgb(213, 213, 213);
      border-radius: 12px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        mix-blend-mode: darken;
      }
    }
  }

  .amount {
    // width: 20%;
    @media screen and (max-width: $breakpoint-allmobile) {
      order: 3;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .reward_value {
      color: $title-color;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: $main-color-50;
      margin-bottom: 12px;
    }

    strong {
      font-weight: bold;
      font-size: 18px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: $title-color;
    }
  }

  .stakBtn {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-large) {
      width: 50%;
    }

    @media screen and (max-width: $breakpoint-allmobile) {
      order: 2;
      width: 100%;
      margin-bottom: 24px;
      position: relative;
    }

    .Cusbtn {
      width: 70%;

      @media screen and (max-width: $breakpoint-allmobile) {
        width: 100%;
      }

      .stackbutton {
        height: 35px;

        @media screen and (max-width: $breakpoint-large) {
          margin-top: 12px;
        }

        @media screen and (max-width: $breakpoint-allmobile) {
          height: 32px;
          margin-top: 24px;
        }
      }
    }

    .img {
      width: 40px;
      height: 40px;
      background: $white-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $input-border;

      @media screen and (max-width: $breakpoint-allmobile) {
        position: absolute;
        top: -45px;
        right: 0;
      }
    }
  }
}
